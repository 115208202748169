import { IHttpProvider } from '@/core/provider/httpApiProvider'
import { IEntriesForAssetMapper } from '@/core/managers/entriesForAsset/entriesForAssetMapper'
import { APIEntriesForAsset } from '@/core/managers/entriesForAsset/entriesForAssetApi'
import { AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'

export interface IAssetWithPeriodManager {
  fetch:(list: AssetUIModel[], contractId: string, signal : AbortSignal)=> Promise<AssetUIModel[]>
}
// todo test UI with no data into array
export const CreateAssetWithPeriodManager = function (httpProvider: IHttpProvider, periodMapper: IEntriesForAssetMapper): IAssetWithPeriodManager {
  return {
    fetch: async function (list: AssetUIModel[], contractId: string, signal : AbortSignal): Promise<AssetUIModel[]> {
      const str = process.env.VUE_APP_URL + `planning/contracts/${contractId}/periods`
      const result = await httpProvider.get<APIEntriesForAsset>(str, signal)
      const _t = list.filter(x => x.contractId === parseInt(contractId))
      return periodMapper.fetchDecoupling(_t, result)
    }
  }
}
