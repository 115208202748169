
import DashboardsListMenuShell from '@/components/buttons/DashboardsListMenuShell.vue'
import DashboardCard from '@/components/cards/DashboardCard.vue'
import * as DashboardManager from '@/core/managers/dashboard.manager'
import { DashboardService } from '@/core/model/dashboard.model'
import { defineComponent, Ref, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    DashboardCard,
    DashboardsListMenuShell
  },
  name: 'Dashboard.list.vue',
  setup () {
    const toast = useToast()
    const error = ref('')
    const loading = ref(true)
    const services: Ref<DashboardService[]> = ref([])
    const allSelected = ref(true)
    return {
      toast,
      error,
      loading,
      services,
      allSelected
    }
  },
  created () {
    // renaud
    DashboardManager.Manager.list().then((response) => {
      this.loading = false
      if (!response.isError && response.data) {
        const services = response.data.categories
        this.services = services.map((s) => ({ ...s, active: true }))
        for (const service of this.services) {
          for (const ser of service.services) {
            ser.active = true
            for (const dashboard of (ser as any).dashboards) {
              dashboard.active = true
            }
          }
        }
      } else {
        this.error = this.$t('allDashboards.error', {
          error: response.Message
        })
        this.toast.error(this.error)
      }
    })
  },
  watch: {
    services: {
      deep: true,
      immediate: true,
      handler () {
        // for (const service of this.services) {
        //   const checked = service.services.every(({ active }) => !active)
        // }
        this.allSelected = !this.allSelected
      }
    }
  },
  methods: {
    filterServices ($event: string) {
      // behaviour if search field is empty
      if ($event === '') {
        this.services = this.services.map((s) => ({ ...s, active: true }))
        for (const service of this.services) {
          for (const ser of service.services) {
            ser.active = true
            for (const dashboard of (ser as any).dashboards) {
              dashboard.active = true
            }
          }
        }
      } else {
        // behaviour with search input
        this.services = this.services.map((s) => ({ ...s, active: false }))
        for (const service of this.services) {
          for (const ser of service.services) {
            ser.active = false
            for (const dashboard of (ser as any).dashboards) {
              dashboard.active = false
            }
          }
        }
        for (const service of this.services) {
          for (const ser of service.services) {
            (ser as any).dashboards.forEach(
              (d: any) => {
                if (d.title.toLowerCase().includes($event.toLowerCase()) || d.description.toLowerCase().includes($event.toLowerCase())) {
                  service.active = true
                  ser.active = true
                  d.active = true
                }
              }
            )
          }
        }
      }
    }
  }
})
