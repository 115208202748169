import { DOMEvent } from '@/core/model/event.model'
import { Ref, ref } from 'vue'

export default function manageReportsMenu () : {
    filtersDisplayed: Ref<boolean>,
    closeFilters: ($event: DOMEvent<HTMLElement>) => void,
    openFilters: () => void
    } {
  const filtersDisplayed = ref(false)

  function closeFilters ($event: DOMEvent<HTMLElement>) {
    if ($event.target && $event.target.matches('.w-screen')) {
      document.body.classList.remove('overflow-hidden')
      filtersDisplayed.value = false
    }
  }

  function openFilters () {
    document.body.classList.add('overflow-hidden')
    filtersDisplayed.value = true
  }

  return { filtersDisplayed, closeFilters, openFilters }
}
