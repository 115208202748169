const accordionBodyExpandEnd = (e: Event) => {
  const accordionBodyElement:HTMLElement = e.target as HTMLElement
  accordionBodyElement.removeEventListener('transitionend', accordionBodyExpandEnd)
  accordionBodyElement.style.overflowY = 'auto'
}
const accordionBodyCollapseEnd = (e: Event) => {
  const wrapper = document.querySelector('[data-accordion-type=wrapper]') as HTMLElement
  const accordionBodyElement:HTMLElement = e.target as HTMLElement
  accordionBodyElement.removeEventListener('transitionend', accordionBodyCollapseEnd)
  const sectionClickedElement = wrapper.querySelector('[data-clicked]') as HTMLElement
  sectionClickedElement.removeAttribute('data-clicked')
  sectionClickedElement.setAttribute('data-accordion-expanded', 'true');
  (sectionClickedElement.querySelector('[data-accordion-type=body]') as HTMLElement).addEventListener('transitionend', accordionBodyExpandEnd)
}
const onClick = function (e: Event) {
  e.preventDefault()
  const wrapper = document.querySelector('[data-accordion-type=wrapper]') as HTMLElement
  const clickedElement = e.currentTarget as HTMLElement
  clickedElement.removeEventListener('click', onClick)
  const sectionClickedElement = clickedElement.parentElement as HTMLElement
  sectionClickedElement.setAttribute('data-clicked', 'true')
  const sectionToClose = wrapper.querySelector('[data-accordion-expanded=true]') as HTMLElement
  sectionToClose.setAttribute('data-accordion-expanded', 'false')
  const linkToClose = sectionToClose.querySelector('[data-accordion-type=link]') as HTMLElement
  linkToClose.addEventListener('click', onClick)
  const accordionBodyElement:HTMLElement = sectionToClose.querySelector('[data-accordion-type=body]') as HTMLElement
  accordionBodyElement.style.overflowY = 'hidden'
  accordionBodyElement.addEventListener('transitionend', accordionBodyCollapseEnd)
}
const setEventListener = function (wrapper : HTMLElement) : void {
  wrapper.querySelectorAll('[data-accordion-expanded=false]').forEach(sectionElement => {
    const linkElement = (sectionElement.querySelector('[data-accordion-type=link]') as HTMLElement)
    linkElement.removeEventListener('click', onClick)
    linkElement.addEventListener('click', onClick)
  })
}
export const AccordionDirective = {
  mounted (wrapper: HTMLElement): void {
    setEventListener(wrapper)
  },
  updated (wrapper: HTMLElement): void {
    setEventListener(wrapper)
  }
}
