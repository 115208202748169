
import { defineComponent } from 'vue'
import DownChevronIcon from '@/components/icons/DownChevronIcon.vue'

export default defineComponent({
  name: 'BackToTop',
  components: { DownChevronIcon },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollBtn = this.$refs.scrollTopButton
      if (window.scrollY > 0) {
        (scrollBtn as HTMLElement).classList.remove('hidden')
      } else {
        (scrollBtn as HTMLElement).classList.add('hidden')
      }
    },
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
})
