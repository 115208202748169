import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full w-full"
}
const _hoisted_2 = { class: "h-full w-full flex flex-col" }
const _hoisted_3 = {
  class: "body body-2 body-alt block",
  style: {"font-family":"'arial'"}
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "body body-1" }
const _hoisted_7 = { class: "!text-text-alt" }

import { computed } from 'vue'
import { KpiInfo, KpiModel } from '@/core/model/kpi.model'
import FormatService from '@/core/services/format.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'standard',
  props: {
    kpi: {},
    values: {}
  },
  setup(__props: any) {

const props = __props

const kpiClass = computed(() => {
  if (props.values && props.values.kpis && props.values.kpis.length > 1) {
    return 'w-2/5 flex flex-col justify-center gap-2'
  }
  return 'w-2/5'
})

return (_ctx: any,_cache: any) => {
  return (_ctx.values)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(kpiClass.value)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values.kpis, (kpiValue, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h3", {
                      class: "truncate",
                      title: kpiValue.label ?? _ctx.kpi.label
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_unref(FormatService).formatNumber(+kpiValue.value)) + " " + _toDisplayString(kpiValue.unit), 1)
                    ], 8, _hoisted_5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(kpiValue.label ?? _ctx.kpi.label), 1)
                ])
              ]))
            }), 128))
          ], 2)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})