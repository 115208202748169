
import { defineComponent, ref } from 'vue'
import CrossIcon from './icons/CrossIcon.vue'
import SearchIcon from './icons/SearchIcon.vue'

export default defineComponent({
  components: { SearchIcon, CrossIcon },
  name: 'SearchInput',
  props: {
    placeholder: String,
    modelValue: { type: [String, Number] }
  },
  setup () {
    const search = ref('')
    return { search }
  }
})
