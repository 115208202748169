
import { defineComponent, PropType } from 'vue'
import { useToast } from 'vue-toastification'
import ReportManager from '@/core/managers/report.manager'
import ReportAttributeTab from '@/components/reports/categories/services/reports/attributes/ReportAttributeTab.vue'
import ReportAttributeTabLoading from '@/components/reports/categories/services/reports/attributes/ReportAttributeTab.loading.vue'
import ReportInstanceRow from '@/components/reports/categories/services/reports/list/ReportRow.vue'
import ReportInstanceLoading from '@/components/reports/categories/services/reports/list/ReportInstanceLoading.vue'
import AssetReportForm from '@/components/reports/categories/services/reports/form/AssetReportForm.vue'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import { ReportAttributeItem, ReportDetail } from '@/core/model/report.model'
import { AssetApiModel } from '@/core/model/fleet.model'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import FormatService from '@/core/services/format.service'
import Pagination from '@/components/common/pagination.vue'

export default defineComponent({
  name: 'ReportsById',
  components: {
    GenericCardShell,
    ReportAttributeTab,
    ReportInstanceRow,
    ReportInstanceLoading,
    ReportAttributeTabLoading,
    AssetReportForm,
    Treeselect,
    Pagination
  },
  props: {
    id: { type: Number, required: true },
    reportTitle: { type: String, required: true },
    assets: { type: Array as PropType<AssetApiModel[]>, required: true },
    assetId: { type: Number },
    isInReports: { type: Boolean, default: false },
    showForm: { type: Boolean, default: false }
  },

  data () {
    return {
      totalPages: 0,
      currentPage: 0,
      attributeId: undefined as number|undefined,
      loading: true,
      loadingInstance: true,
      report: {} as ReportDetail,
      // modelValue is used for treeselect - we don't use selected asset cause the update of value is not synch in time. We haave a delay
      modelValue: null as AssetApiModel|null,
      selectedAsset: {} as AssetApiModel,
      instances: [] as ReportAttributeItem[],
      toast: useToast()
    }
  },
  async created () {
    if (this.assetId && this.assets.length) {
      this.selectedAsset = this.assets.find(a => a.id === this.assetId) as AssetApiModel
    } else if (this.assetId) {
      this.selectedAsset = { id: this.assetId } as AssetApiModel
    } else {
      this.selectedAsset = this.assets[0]
    }
    this.modelValue = this.selectedAsset
    await this.retrieveReportDetail()
  },
  methods: {
    async selectAsset ($event: AssetApiModel) {
      this.selectedAsset = $event
      await this.retrieveReportDetail()
    },
    async retrieveReportDetail () {
      this.loading = true
      this.attributeId = undefined

      const response = await ReportManager.detailAsset(this.id, this.selectedAsset.id)

      if (response.data && !response.isError) {
        this.report = response.data
      }
      this.loading = false
      await this.fetchReports()
    },
    async closeForm () {
      this.$emit('closeForm')
      await this.retrieveReportDetail()
    },
    async updateSelectedAttribute (selectedAttributeId: number|undefined) {
      this.attributeId = selectedAttributeId
      await this.fetchReports()
    },
    rotateArrow () {
      const TreeselectElement = document.getElementById(this.TreeselectId)
      if (TreeselectElement === null) return
      const arrowContainer = TreeselectElement.querySelectorAll('.vue-treeselect__control-arrow-container')
      arrowContainer[0].classList.toggle('rotate-x-180')
    },
    normalizer (node: AssetApiModel) {
      return {
        id: node.id,
        label: node.name
      }
    },
    async fetchReports (pageIndex = 0) {
      if (!this.selectedAsset.id) return

      this.loadingInstance = true
      this.instances = []
      if (this.attributeId) {
        this.instances = await this.retrieveReportInstance(this.attributeId, pageIndex)
      } else {
        this.instances = await this.retrieveDefaultInstance()
      }
      this.loadingInstance = false
    },
    async retrieveReportInstance (attributeId: number, pageIndex = 0): Promise<ReportAttributeItem[]> {
      const numberOfElementPerPage = 5
      const response = await ReportManager.byAttributeAsset(this.id, this.selectedAsset.id, attributeId, pageIndex, numberOfElementPerPage)
      if (response.isError) {
        const error = this.$t('allReports.error', {
          error: response.Message
        })
        this.toast.error(error)
      }

      this.currentPage = response.data?.pageIndex as number + 1
      this.totalPages = response.data?.totalPages as number
      return response.data?.items ?? []
    },
    async changePageIndex (selectedPage: number) {
      await this.fetchReports(selectedPage - 1) // pageIndex start at 0 - selectedPage start at 1
    },
    async retrieveDefaultInstance (): Promise<ReportAttributeItem[]> {
      let instances = [] as ReportAttributeItem[]
      for (const a of this.report.attributes) {
        instances = instances.concat(a.instances.map(i => {
          return {
            id: i.id,
            name: i.name,
            instanceDate: i.instanceDate,
            fileFormat: i.fileFormat,
            period: a.name
          } as ReportAttributeItem
        }))
      }
      this.currentPage = 0
      this.totalPages = 0
      // sort instance by instanceDate
      return instances.sort((a, b) => new Date(b.instanceDate).getTime() - new Date(a.instanceDate).getTime())
    },
    async download (item: ReportAttributeItem) {
      if (item.IsDownloading) return
      item.IsDownloading = true

      try {
        const filename = item.name + ' - ' + FormatService.formatDate(item.instanceDate) + '.' + item.fileFormat
        const displayFilename = this.reportTitle + '-' + item.name + '.' + item.fileFormat
        await ReportManager.DownloadInstance(displayFilename, filename, item.tinyGuid)
      } finally {
        item.IsDownloading = false
      }
    }
  },
  computed: {
    TreeselectId () {
      return 'report-' + this.id.toString() + '-AssetTreeSelect'
    }
  }
})
