
import { defineComponent } from 'vue'
import ZoomInIcon from '@/components/icons/ZoomInIcon.vue'
import ZoomOutIcon from '@/components/icons/ZoomOutIcon.vue'

export default defineComponent({
  name: 'TimelineZoom',
  components: { ZoomInIcon, ZoomOutIcon },
  props: { zoomLevel: Number }
})
