import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "mt-6" }
const _hoisted_2 = { class: "flex flex-col lg:flex-row text-gray-200 w-full" }
const _hoisted_3 = {
  key: 0,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_4 = {
  key: 1,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_5 = { class: "border-b border-b-bg-3 text-text-alt" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 2,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_9 = {
  key: 3,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_10 = {
  key: 4,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_11 = { class: "w-[70%] bg-bg-0/50 backdrop-blur-md h-full transit" }
const _hoisted_12 = { class: "border-b border-b-bg-3 text-text-alt" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "w-[70%] bg-bg-0/50 backdrop-blur-md h-full transit" }
const _hoisted_16 = { class: "border-b border-b-bg-3 text-text-alt" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "grow ml-0 lg:ml-6 w-full lg:max-w-[80%]" }
const _hoisted_20 = {
  key: 0,
  class: "flex lg:hidden justify-center my-7"
}
const _hoisted_21 = { class: "ml-3 body-1 font-bold" }
const _hoisted_22 = {
  key: 1,
  class: "flex lg:hidden justify-center my-7"
}
const _hoisted_23 = {
  key: 2,
  class: "flex lg:hidden justify-center my-7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsListMenu = _resolveComponent("ReportsListMenu")!
  const _component_FilterIcon = _resolveComponent("FilterIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (
          !_ctx.error && !_ctx.loading && _ctx.categories.length && !_ctx.isDashboard && !_ctx.isAsset
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
              return (_openBlock(), _createBlock(_component_ReportsListMenu, {
                key: i,
                category: category,
                isDashboard: _ctx.isDashboard,
                onShowReport: ($event: any) => (_ctx.handleDetailReport($event, category)),
                onShowService: ($event: any) => (_ctx.handleServiceReport($event, category)),
                allActive: _ctx.allActive,
                allActiveInCategory: _ctx.allActiveInCategory
              }, null, 8, ["category", "isDashboard", "onShowReport", "onShowService", "allActive", "allActiveInCategory"]))
            }), 128))
          ]))
        : (
          !_ctx.error && !_ctx.loading && _ctx.categories.length && !_ctx.isDashboard && _ctx.isAsset
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.categories && _ctx.categories.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
                        return (_openBlock(), _createElementBlock("div", { key: i }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.groups, (service, j) => {
                            return (_openBlock(), _createElementBlock("div", { key: j }, [
                              _withDirectives(_createElementVNode("div", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.groups, (report, k) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: k,
                                    class: _normalizeClass(["body-2 p-4 cursor-pointer", { 'font-bold': report.active }]),
                                    onClick: ($event: any) => (_ctx.handleVesselReport(report))
                                  }, _toDisplayString(report.title), 11, _hoisted_7))
                                }), 128))
                              ], 512), [
                                [_vShow, _ctx.open]
                              ])
                            ]))
                          }), 128))
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (!_ctx.error && !_ctx.loading && !_ctx.categories.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("allReports.notFound")), 1))
            : (_ctx.error && !_ctx.loading && !_ctx.categories.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.error), 1))
              : (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                      return _createVNode(_component_ReportsListMenu, {
                        key: i,
                        loading: _ctx.loading,
                        onShowList: _cache[0] || (_cache[0] = ($event: any) => (_ctx.report.active = !_ctx.report.active))
                      }, null, 8, ["loading"])
                    }), 64))
                  ]))
                : _createCommentVNode("", true),
      (!_ctx.error && !_ctx.loading && _ctx.categories.length && !_ctx.isAsset)
        ? (_openBlock(), _createElementBlock("div", {
            key: 5,
            class: _normalizeClass(["block lg:hidden fixed h-[calc(100vh-56px-63px)] z-10 top-[63px] transit w-screen", _ctx.filtersDisplayed ? 'left-0' : '-left-full']),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeFilters && _ctx.closeFilters(...args)))
          }, [
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
                return (_openBlock(), _createBlock(_component_ReportsListMenu, {
                  key: i,
                  category: category,
                  isDashboard: _ctx.isDashboard,
                  onShowDetail: ($event: any) => (_ctx.handleDetailDashboard($event, category)),
                  onShowReport: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show-report', $event))),
                  onShowService: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleServiceReport($event, _ctx.report))),
                  class: "border"
                }, null, 8, ["category", "isDashboard", "onShowDetail"]))
              }), 128)),
              _createElementVNode("div", _hoisted_12, [
                (_ctx.categories && _ctx.categories.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
                        return (_openBlock(), _createElementBlock("div", { key: i }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.groups, (service, j) => {
                            return (_openBlock(), _createElementBlock("div", { key: j }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.groups, (report, k) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: k,
                                  class: "body-2 p-4 cursor-pointer",
                                  onClick: ($event: any) => (_ctx.handleVesselReport(report))
                                }, _toDisplayString(report.title), 9, _hoisted_14))
                              }), 128))
                            ]))
                          }), 128))
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2))
        : (!_ctx.error && !_ctx.loading && _ctx.categories.length && _ctx.isAsset)
          ? (_openBlock(), _createElementBlock("div", {
              key: 6,
              class: _normalizeClass(["block lg:hidden fixed h-[calc(100vh-56px-63px)] z-10 top-[63px] transit w-screen", _ctx.filtersDisplayed ? 'left-0' : '-left-full']),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeFilters && _ctx.closeFilters(...args)))
            }, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  (_ctx.categories && _ctx.categories.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
                          return (_openBlock(), _createElementBlock("div", { key: i }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.groups, (service, j) => {
                              return (_openBlock(), _createElementBlock("div", { key: j }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.groups, (report, k) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: k,
                                    class: "body-2 p-4 cursor-pointer",
                                    onClick: ($event: any) => (_ctx.handleVesselReport(report))
                                  }, _toDisplayString(report.title), 9, _hoisted_18))
                                }), 128))
                              ]))
                            }), 128))
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 2))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_19, [
        (_ctx.categories.length && !_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createElementVNode("button", {
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.openFilters && _ctx.openFilters(...args))),
                class: "text-text-title stroke-text-title py-2 px-4 border border-text-title rounded-lg flex items-center filters-button"
              }, [
                _createVNode(_component_FilterIcon, { class: "h-4" }),
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("allDashboards.filters")), 1)
              ])
            ]))
          : (!_ctx.error && !_ctx.loading && !_ctx.categories.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t("allReports.notFound")), 1))
            : (_ctx.error && !_ctx.loading && !_ctx.categories.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.error), 1))
              : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}