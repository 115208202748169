import svgMaker from '@/map/core/service/popover/svgMaker'
import { Router } from 'vue-router'

export interface IConfigPopover{
  offset: number
  html: HTMLElement,
  radius : number
  stroke : number
  svgDim : number
  demiCircle : number
  query : Record<string, string | undefined>
}

// @ts-ignore
export default {
  append: function (anchor : HTMLElement, conf : IConfigPopover, type :string, router : Router): void {
    // todo remove querySelector
    const mapElement = document.querySelector('.leaflet-container')
    const mapDim = (mapElement as HTMLElement).getBoundingClientRect()
    const markerDim = anchor.getBoundingClientRect()

    document.querySelectorAll('.zIndex999, .zIndex998').forEach(element => element.classList.remove('zIndexTop'))

    const wrapper = document.createElement('div')
    wrapper.setAttribute('data-panel-type', type)
    anchor.appendChild(wrapper)

    const panel = conf.html
    panel.setAttribute('class', 'shadow-lg shadow-slate-800/40 bg-slate-800 border text-slate-300 border-2 border-blue-500 rounded-lg')
    panel.style.position = 'absolute'

    if (type === 'static') {
      panel.addEventListener('click', async function (e:Event) {
        await router.push({
          query: { ...router.currentRoute.value.query, stepPopoverAdd: undefined, staticStep: (e.target as HTMLElement).dataset.stepIndex !== '-1' ? (e.target as HTMLElement).dataset.stepIndex : undefined }
        })
      })
      document.querySelectorAll('.zIndex998').forEach(element => element.classList.remove('zIndex998'))
      anchor.classList.add('zIndex998')
    } else {
      document.querySelectorAll('.zIndex999').forEach(element => element.classList.remove('zIndex999'))
      anchor.classList.add('zIndex999')
    }
    wrapper.appendChild(panel)
    const panelDim = panel.getBoundingClientRect()
    const totalWidth = conf.svgDim + panelDim.width
    const isLeft = markerDim.x + totalWidth > mapDim.width

    panel.style.top = `${conf.svgDim - (conf.demiCircle - conf.offset)}px`
    if (isLeft) {
      panel.style.borderTopRightRadius = '0'
      panel.style.left = `${(panelDim.width + (conf.svgDim - conf.demiCircle - conf.offset)) * -1}px`
    } else {
      panel.style.borderTopLeftRadius = '0'
      panel.style.left = `${conf.svgDim - (conf.demiCircle - conf.offset)}px`
    }
    wrapper.appendChild(svgMaker.getPosition(isLeft, conf, router))
  }
}
