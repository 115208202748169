import { computed, watch } from 'vue'
import { Map } from 'leaflet'
import { Router } from 'vue-router'
import categoryStore from '@/map/core/bll/category/category.store'
import { AssetModel } from '@/map/core/bll/category/categoryModel'

export default {
  mounted: function (map: Map, router: Router): void {
    const flyTo = function (value :string): void {
      const asset = categoryStore.state.list.find(a => a.assetCode === value) as AssetModel
      if (asset) {
        map.flyTo(asset.latLng, 8)
      }
    }
    if (categoryStore.state.initialized && !!router.currentRoute.value.query.flyTo) {
      flyTo(router.currentRoute.value.query.flyTo as string)
    } else {
      const subject = computed(function ():string {
        return categoryStore.state.initialized && router.currentRoute.value.query.flyTo ? router.currentRoute.value.query.flyTo as string : '-1'
      })

      watch(subject, function (value:string) {
        if (value !== '-1') {
          flyTo(value)
        }
      })
    }
  }
}
