import { CategoryModel } from '@/core/model/category.model'
import { FilterFormatted } from '@/core/model/filter.model'
import { Label } from '@/core/model/label.model'
import { FiltersSettings, FleetSettings } from '@/core/model/settings.model'
import { UserModel } from '@/core/model/user.model'
import { Commit, createStore } from 'vuex'

export interface State {
  list: CategoryModel[],
  labels: Label[]
  filters: FilterFormatted[]
  fleetSettings?: FleetSettings
  filtersSettings?: FiltersSettings
}

export default createStore({
  state () {
    return {
      list: [],
      labels: [],
      filters: [],
      fleetSettings: undefined,
      filtersSettings: undefined
    }
  },
  mutations: {
    updateServiceList (state: State, list) {
      state.list = list
    },
    updateLabelsList (state: State, labels) {
      state.labels = labels
    },
    updateFiltersList (state: State, filters) {
      state.filters = filters
    },
    updateFleetSettings (state: State, settings) {
      state.fleetSettings = settings
    },
    updateFiltersSettings (state: State, settings) {
      state.filtersSettings = settings
    }
  },
  actions: {
    setServiceList (store: { commit: Commit }, payload: { list: CategoryModel[] }) {
      store.commit('updateServiceList', payload)
    },
    setLabelsList (store: { commit: Commit }, payload: { labels: Label[] }) {
      store.commit('updateLabelsList', payload)
    },
    setFiltersList (store: { commit: Commit }, payload: FilterFormatted[]) {
      const filtersWithoutEmpty = []
      for (const filter of Object.values(payload)) {
        if ((Array.isArray(filter.members) && filter.members.length) || (!Array.isArray(filter.members) && filter.members)) {
          filtersWithoutEmpty.push(filter)
        }
      }
      store.commit('updateFiltersList', filtersWithoutEmpty)
    },
    defineUser (store: { commit: Commit }, payload: UserModel) {
      store.commit('updateUserProfile', payload)
    },
    setFleetSettings (store: { commit: Commit }, payload: FleetSettings) {
      store.commit('updateFleetSettings', payload)
    },
    setFiltersSettings (store: { commit: Commit }, payload: FiltersSettings) {
      store.commit('updateFiltersSettings', payload)
    },
    defineUserOid (store: { commit: Commit }, payload: string) {
      store.commit('updateUserOid', payload)
    },
    defineContext (store: { commit: Commit }, payload: string) {
      store.commit('updateContext', payload)
    },
    defineSessionId (store: { commit: Commit }, payload: string) {
      store.commit('updateSessionId', payload)
    }
  },
  modules: {
  }
})
