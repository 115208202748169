
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'observer',
  data () {
    return {
      observer: null as IntersectionObserver | null
    }
  },
  props: {
    root: {
      type: Object as PropType<Element | undefined | null>,
      default: null
    },
    rootMargin: {
      type: [String],
      default: '0px'
    },
    threshold: {
      type: Array as PropType<Array<number>>,
      default: () => [0]
    }
  },
  methods: {
    unobserve () {
      if (this.observer) { this.observer.unobserve(this.$el) }
    }
  },
  mounted () {
    const options = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: 0.3
    }
    this.observer = new IntersectionObserver(entries => {
      this.$emit('on-change', entries[0], this.unobserve)
    // eslint-disable-next-line no-undef
    }, options as IntersectionObserverInit)
    if (this.observer) { this.observer.observe(this.$el) }
  },
  beforeUnmount () {
    if (this.observer) {
      this.unobserve()
      this.observer = null
    }
  }
})
