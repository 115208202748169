import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "py-3 lg:py-6 h-100 relative" }
const _hoisted_2 = { class: "flex flex-wrap gap-4" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row relative mt-5" }
const _hoisted_4 = { class: "grow mr-6 w-full" }
const _hoisted_5 = { class: "grid grid-cols-1 lg:grid-cols-6 mt-6 gap-4" }

import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import chartCardLoading from '@/components/analytics/charts/card.loading.vue'
import kpiCardLoading from '@/components/analytics/kpi/card.loading.vue'
import serviceTitle from '@/components/analytics/services/title.vue'
import HScroller from '@/components/common/horizontal-arrow-scoller.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'details-page.loading',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(serviceTitle, {
      loading: true,
      link: 'analytics'
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
        return _createElementVNode("div", { key: i }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "select custom-select w-70 skeleton" }, null, -1)
        ]))
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(HScroller, null, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
              return _createVNode(kpiCardLoading, { key: i })
            }), 64))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(GenericCardShell, {
            class: _normalizeClass(["h-[460px]", `grid-6`])
          }, {
            default: _withCtx(() => [
              _createVNode(chartCardLoading)
            ]),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "mt-20 mb-10 border-t-gray-800" }, null, -1))
      ])
    ])
  ]))
}
}

})