
import { defineComponent, DirectiveBinding, inject, PropType } from 'vue'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'

export default defineComponent({
  name: 'timeLineRowLeft',
  props: {
    asset: {
      type: Object as PropType<AssetUIModel>,
      required: true
    }
  },
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  },
  directives: {
    displayContent: {
      mounted (element: HTMLElement, binding: DirectiveBinding) {
        const periodWidth = binding.value.periodWidth
        const elementWidth = 15 + element.getBoundingClientRect().width
        if (periodWidth < elementWidth) {
          element.style.display = 'none'
        }
      }
    }
  }
})
