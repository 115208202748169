
import TopButton from '@/components/buttons/TopButton.vue'
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import '@lottiefiles/lottie-player'
import { defineComponent, inject, InjectionKey } from 'vue'
import { useRouter } from 'vue-router'
import * as UserManager from '@/core/managers/user.manager'
import store from '@/store'

export default defineComponent({
  inject: ['ILoginService'],
  props: {
    type: { type: String, default: 'notFound' }
  },
  components: { TopButton },
  setup () {
    const router = useRouter()
    if (router.currentRoute.value.name === 'unauthorized') {
      UserManager.Manager.detail().then((response) => {
        if (!response.isError && response.data) {
          store.dispatch('defineUser', response.data)
          router.push({ name: 'home' })
        }
      })
    }

    const ILoginService: InjectionKey<ILoginService> | undefined =
      inject('ILoginService')
    const logout = () => {
      if (ILoginService) {
        (ILoginService as unknown as ILoginService).SignOut().then(() => {
          router.push({ name: 'login' })
        })
      }
    }
    return {
      logout
    }
  }
})
