import { AssetModel, CategoryModel, GetCategoryModel } from '@/map/core/bll/category/categoryModel'
import { ApiClient } from '@/map/core/dal/apiClient'
import { CategoryMapper } from '@/map/core/bll/category/category.mapper'
import { divIcon, LayerGroup, Marker, marker } from 'leaflet'
import { groupAssetLayer } from '@/map/core/bll/category/category.group'

const fetch = function (): Promise<{ list: AssetModel[], group: CategoryModel[] }> {
  return new Promise((resolve, reject) => {
    // api/Asset/groupedByCategoryWithLastPosition
    ApiClient.list<{ assetCategories : GetCategoryModel[] }>('map/Asset/groupedByCategoryWithLastPosition').then(result => {
      const list: AssetModel[] = []
      const group: CategoryModel[] = []
      result.assetCategories.forEach(category => {
        group.push({
          code: category.categoryCode,
          name: category.categoryName
        })
        category.assets.forEach(asset => {
          const temp = CategoryMapper(asset, category.categoryCode)
          list.push(temp)

          const markerTemp = marker(temp.latLng,
            {
              attribution: `${temp.assetCode}_${temp.isVessel}`,
              riseOnHover: true,
              icon: divIcon({
                // assetCode and assetType required for mouseOver on map pane event-delegation
                // data-is-vessel && data-asset-code used by fleet
                // data-origin-color && data-asset-code used setBlue
                // data-popover-type for popover
                // todo check if no popover-type && asset-code
                html: temp.isVessel
                  ? `<img data-asset-code="${temp.assetCode}" data-is-vessel="${temp.isVessel}"  data-popover-type="asset" data-origin-color="${temp.vesselColor}" src="${temp.assetMarkerUrl}" alt="${temp.assetName}" style="width: ${temp.markerDim + 'px'} ; height: ${temp.markerDim + 'px'};transform: rotate( ${temp.heading + 'deg'})" >`
                  : `<img data-asset-code="${temp.assetCode}" data-is-vessel="${temp.isVessel}"  data-popover-type="asset"  src="${temp.assetMarkerUrl}" alt="${temp.assetName}" style="width: ${temp.markerDim + 'px'} ; height: ${temp.markerDim + 'px'};" >`,
                className: 'bg-transparent ',
                iconAnchor: [temp.markerDim / 2, temp.markerDim / 2]
              })
            })

          groupAssetLayer.addLayer(markerTemp)
        })
      })
      resolve({ list, group })
    }).catch((err:string) => {
      reject(err)
    })
  })
}

const toggleLayer = function (group : LayerGroup, code : string, active : boolean):void {
  group.getLayers().forEach(layer => {
    if (layer instanceof Marker && ((layer as Marker).options.attribution as string).split('_')[0] === code) {
      (layer as Marker).options.opacity = active ? 1 : 0;
      (layer as Marker).options.interactive = active
      layer.remove()
      group.addLayer(layer)
    }
  })
}

const disabledLayer = function (group : LayerGroup, code : string, active : boolean):void {
  group.getLayers().forEach(layer => {
    if (layer instanceof Marker && ((layer as Marker).options.attribution as string).split('_')[0] === code) {
      (layer as Marker).options.interactive = active
      layer.remove()
      group.addLayer(layer)
    }
  })
}

export default {
  fetch,
  toggleLayer,
  disabledLayer
}
