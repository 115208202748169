import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_line_row_left = _resolveComponent("time-line-row-left")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_time_line_row_right = _resolveComponent("time-line-row-right")!
  const _component_v_row = _resolveComponent("v-row")!
  const _directive_scroll = _resolveDirective("scroll")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "2",
        class: "pt-4 bg-bg-2"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filter(this.filterText, _ctx.assets), (item, index) => {
            return (_openBlock(), _createBlock(_component_time_line_row_left, {
              key: index,
              asset: item,
              "contract-id": _ctx.$route.params.contractId
            }, null, 8, ["asset", "contract-id"]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        cols: "10",
        class: "pt-4",
        style: {"overflow-x":"hidden","position":"relative"}
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            id: "periodWrapper",
            style: _normalizeStyle([{ width : _ctx.timelineHelper.wrapperWidth +'px'}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filter(this.filterText,_ctx.assets), (item, index) => {
              return (_openBlock(), _createBlock(_component_time_line_row_right, {
                key: index,
                asset: item
              }, null, 8, ["asset"]))
            }), 128))
          ], 4)), [
            [_directive_scroll]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}