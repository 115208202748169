import { LabelModel } from '@/core/model/label.model'
import ColoursService from '@/core/services/colours.service'
import { ApiResult, http } from '@/core/services/request.service'
import store from '@/store'

export const Manager = {
  list (): void {
    const provisionalLabels1 =
      [
        {
          text: 'Reported consumption',
          color: ColoursService.randomColour()
        },
        {
          text: 'Optimal consumption',
          color: ColoursService.randomColour()
        },
        {
          text: 'Fuel saved',
          color: ColoursService.randomColour()
        },
        {
          text: 'Potential Fuel Savings',
          color: ColoursService.randomColour()
        },
        {
          text: 'Idle Time Trend',
          color: ColoursService.randomColour()
        },
        {
          text: 'Top 10 Idle time duration by location',
          color: ColoursService.randomColour()
        },
        {
          text: 'Stand by types',
          color: ColoursService.randomColour()
        },
        {
          text: 'CO² Emmissions',
          color: ColoursService.randomColour()
        },
        {
          text: 'SOX Emmissions',
          color: ColoursService.randomColour()
        },
        {
          text: 'NOX Emmissions',
          color: ColoursService.randomColour()
        }, {
          text: 'Top 8 spent time by activity',
          color: ColoursService.randomColour()
        },
        {
          text: 'Remaining on board',
          color: ColoursService.randomColour()
        },
        {
          text: 'Product loaded and discharged',
          color: ColoursService.randomColour()
        },
        {
          text: 'Number of PAX',
          color: ColoursService.randomColour()
        }
      ]

    const provisionalLabels2 = [
      {
        text: 'Reported Consumtion',
        color: provisionalLabels1[0].color
      },
      {
        text: 'Optimal Consumtion',
        color: provisionalLabels1[1].color
      },
      {
        text: 'CO² Emissions',
        color: provisionalLabels1[7].color
      },
      {
        text: 'SOX Emissions',
        color: provisionalLabels1[8].color
      },
      {
        text: 'NOX Emissions',
        color: provisionalLabels1[9].color
      }
    ]

    const provisionalLabels = provisionalLabels1.concat(provisionalLabels2)

    http.request<LabelModel>('get', 'Labels')
      .then((rep: ApiResult<LabelModel>) => {
        const labels = rep.data && rep.data.labels.length ? rep.data.labels : provisionalLabels
        store.dispatch('setLabelsList', labels)
      }
      )
      .catch(err => err)
  }
}
