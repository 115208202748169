import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "h-full w-full flex flex-row" }
const _hoisted_3 = {
  class: "body body-2 body-alt block",
  style: {"font-family":"'arial'"}
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "truncate" }
const _hoisted_6 = { class: "body body-1" }
const _hoisted_7 = { class: "ml-3 !font-bold" }

import { computed, ref, toRef } from 'vue'
import { KpiInfo, KpiModel } from '@/core/model/kpi.model'
import { ChartInfo, ChartModel } from '@/core/model/chart.model'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import ColoursService from '@/core/services/colours.service'
import FormatService from '@/core/services/format.service'
import CPie from '@/components/analytics/charts/visuals/pie.chart.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'pie-and-kpi',
  props: {
    chartInfo: {},
    kpiInfo: {},
    chart: {},
    kpi: {}
  },
  setup(__props: any) {

const props = __props

const options: IGeneratorOption = {
  height: '125px',
  isDetailed: false,
  title: props.chartInfo.label,
  description: props.chartInfo.description,
  legends: {
    enable: false
  },
  margin: [0, 0, 0, 0]
}

const kpiClass = computed(() => {
  if (props.kpi?.kpis && props.kpi.kpis.length > 1) {
    return 'w-2/5 flex flex-col justify-center gap-2'
  }
  return 'w-2/5'
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.kpi?.kpis)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(kpiClass.value)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpi.kpis, (kpiValue, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                      class: "h-2 w-2 rounded-full mr-1",
                      style: _normalizeStyle(`background-color: ${_unref(ColoursService).getColour(kpiValue.label || _ctx.kpiInfo.label)}; min-width: 0.5rem`)
                    }, null, 4),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(kpiValue.label || _ctx.kpiInfo?.label), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(FormatService).formatNumber(+kpiValue.value)) + " " + _toDisplayString(kpiValue.unit), 1)
                ])
              ]))
            }), 128))
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.chart)
        ? (_openBlock(), _createBlock(CPie, {
            key: 1,
            values: _ctx.chart,
            options: options,
            class: "h-full boorder w-3/5"
          }, null, 8, ["values"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})