
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DownChevronIcon',
  props: {
    btt: {
      type: Boolean, default: false
    }
  }
})
