import { IEntry } from '@/core/managers/entry/entryModel'
import { IHttpProvider } from '@/core/provider/httpApiProvider'
import { IEntryMapper } from '@/core/managers/entry/entryMapper'
import { APIEntry, APIPostEntry } from '@/core/managers/entry/entryApi'

export interface IEntryManager{
  init : IEntry
  find: (contractId: string, assetId: string, periodId:string, signal : AbortSignal)=> Promise<IEntry>
  post : (contractId: string, assetId:string, input: IEntry, signal : AbortSignal)=> Promise<void>
  update: (contractId: string, assetId:string, periodId: string, input: IEntry, signal : AbortSignal)=> Promise<void>
  delete: (contractId: string, assetId:string, entryId : number, signal : AbortSignal)=> Promise<void>
}

export const CreateEntryManager = function (httpProvider: IHttpProvider, entryMapper : IEntryMapper): IEntryManager {
  return {
    init: {
      id: -1,
      status: '',
      isOff: false,
      isOn: false,
      isMissing: false,
      canEditStartDate: false,
      canEditEndDate: false,
      minCalendarTime: null,
      maxCalendarTime: null,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      dailyRate: -1,
      reasonId: null,
      projectId: null,
      clientId: null,
      reasonName: '',
      projectName: '',
      clientName: '',
      reasonList: [],
      clientList: [],
      projectList: [],
      robList: [],
      description: ''
    },
    find: async function (contractId: string, assetId: string, periodId:string, signal : AbortSignal): Promise<IEntry> {
      // /api/planning/contracts/1/assets/1/periods/new
      const result = await httpProvider.get<APIEntry>(process.env.VUE_APP_URL + `planning/contracts/${contractId}/assets/${assetId}/periods/${periodId}`, signal)
      const t = entryMapper.getDecoupling(result)
      return t
    },
    async post (contractId: string, assetId:string, entry: IEntry, signal : AbortSignal) {
      const body = entryMapper.postDecoupling('post', entry)
      await httpProvider.post<APIPostEntry, {id : number}>(process.env.VUE_APP_URL + `planning/contracts/${contractId}/assets/${assetId}/periods`, body, signal)
    },
    async update (contractId: string, assetId:string, entryId:string, entry: IEntry, signal : AbortSignal) {
      const body = entryMapper.postDecoupling('put', entry)
      await httpProvider.put<APIPostEntry>(process.env.VUE_APP_URL + `planning/contracts/${contractId}/assets/${assetId}/periods/${entryId}`, body, signal)
    },
    async delete (contractId: string, assetId:string, entryId:number, signal : AbortSignal) {
      await httpProvider.delete(process.env.VUE_APP_URL + `planning/contracts/${contractId}/assets/${assetId}/periods/${entryId}`, signal)
    }
  }
}
