import popoverService, { IConfigPopover } from '@/map/core/service/popover.service'
import { StepModel } from '@/map/core/bll/step/step.model'
import { Router } from 'vue-router'

const _html = function (step : StepModel, isNavigation : boolean) : HTMLElement {
  const wrapper = document.createElement('div')
  let str = '<div class="p-2">'
  if (isNavigation) {
    str += '<nav class="flex pb-1 border-b border-slate-300"><div style="flex: 1">'

    if (!step.last) {
      str += `<i data-popover-type="static" class="fa-sharp fa-solid fa-circle-arrow-left fa-lg mr-1" data-step-index="${step.index + 1}"></i>`
    } else {
      str += `<i data-popover-type="static" class="text-slate-400 fa-sharp  fa-solid fa-circle-arrow-left fa-lg mr-1" data-step-index="${step.index}" ></i>`
    }

    if (!step.first) {
      str += `<i data-popover-type="static" class="fa-sharp fa-solid fa-circle-arrow-right fa-lg ml-1" data-step-index="${step.index - 1}"></i>`
    } else {
      str += `<i data-popover-type="static" class=" text-slate-400  fa-sharp fa-solid fa-circle-arrow-right fa-lg ml-1" data-step-index="${step.index}" ></i>`
    }

    str += '</div><div><i data-popover-type="static" class="fa fa-circle-xmark fa-lg" data-step-index="-1"></i></div>'

    str += '</nav>'
  }
  str += `
    <section class="pt-1">
      <div class="whitespace-nowrap"><i class="fa-regular fa-clock"></i><span class="mx-2">last position:</span><span class="text-white">${step.utcTime}</span></div>
      <div class="whitespace-nowrap"><i class="fa-regular fa-compass"  style="transform: rotate(45deg)"></i><span class="mx-2">lat:</span><span class="text-white">${step.latLng.lat}</span></div>
      <div class="whitespace-nowrap"><i class="fa-regular fa-compass" style="transform: rotate(135deg)"></i><span class="mx-2">lng:</span><span class="text-white">${step.latLng.lng}</span></div>
      <div class="whitespace-nowrap"><i class="fa-regular fa-gauge-high"></i><span class="mx-2">speed:</span><span class="text-white">${step.speed.toFixed(2)} kn</span></div>
      <div class="whitespace-nowrap"><i class="fa-regular fa-circle-location-arrow"></i><span class="mx-2">heading:</span><span class="text-white">${step.heading.toFixed(2)}°</span></div>
    </section></div>
  `
  wrapper.innerHTML = str

  return wrapper
}

export default {
  append: function (anchor : HTMLElement, step: StepModel, isNavigation : boolean, router : Router):void {
    const conf:IConfigPopover = {
      offset: (step.markerDim / 2),
      html: _html(step, isNavigation),
      radius: step.radius,
      stroke: step.stroke,
      svgDim: step.svgDim,
      demiCircle: step.radius + (step.stroke / 2),
      query: step.query
    }
    popoverService.append(anchor, conf, isNavigation ? 'static' : 'step', router)
  }
}
