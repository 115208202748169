import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center mb-1.5" }
const _hoisted_2 = { class: "body body-1 w-4/5 !font-bold overflow-hidden whitespace-nowrap max-w-5/6 text-ellipsis" }

import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'preview-header',
  props: {
    title: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    _createVNode(RightArrowIcon, { class: "w-6 h-6 ml-6 fill-text-alt stroke-text-alt" })
  ]))
}
}

})