<template>
  <div class="flex flex-col h-full relative">
    <div class="h-1/6">
      <div class="my-10 mx-auto z-20 absolute left-1/2 -translate-x-1/2 text-xl text-center font-bold skeleton w-4/5 max-w-xs">

      </div>
    </div>

    <div class="flex flex-col lg:flex-row h-5/6">
      <div class="h-full flex flex-col w-full m-auto p-4">

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .skeleton {
   @include skeleton(13px, 20px, none);
  }
</style>
