
import { defineComponent } from 'vue'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ReportAttributeTabLoading from '@/components/reports/categories/services/reports/attributes/ReportAttributeTab.loading.vue'
import ReportInstanceLoading from '@/components/reports/categories/services/reports/list/ReportInstanceLoading.vue'

export default defineComponent({
  name: 'ReportsListLoading',
  components: {
    GenericCardShell, ReportAttributeTabLoading, ReportInstanceLoading
  }
})
