import { computed, watch } from 'vue'
import { latLngBounds, Map, point } from 'leaflet'
import categoryStore from '@/map/core/bll/category/category.store'
import zoneStore from '@/map/core/bll/zone/zone.store'
import { Router } from 'vue-router'

export default {
  mounted: function (map: Map, router: Router): void {
    const flyBounds = function (): void {
      map.flyToBounds(latLngBounds(categoryStore.getter.activeBound().concat(zoneStore.getter.activeBound())), { maxZoom: 12, padding: point(5, 5) })
    }
    if (zoneStore.state.initialized && categoryStore.state.initialized && !!router.currentRoute.value.query.flyToBounds) {
      flyBounds()
    } else {
      const subject = computed(function ():boolean {
        return zoneStore.state.initialized && categoryStore.state.initialized && !!router.currentRoute.value.query.flyToBounds
      })

      watch(subject, function (value : boolean) {
        if (value && !router.currentRoute.value.query.flyTo) {
          flyBounds()
        }
      })
    }
  }
}
