import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-1/4 w-full rounded-b-xl flex px-3.5 pt-2 pb-5 justify-between flex-col" }
const _hoisted_2 = { class: "font-bold flex items-center" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "w-full mt-1" }
const _hoisted_5 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FavouriteButton = _resolveComponent("FavouriteButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "h-[210px] w-[280px] aspect-square glassmorphism fleet-card rounded-xl relative overflow-hidden cursor-pointer border border-bg-0 m-auto",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.seeAssetDetails && _ctx.seeAssetDetails(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-3/4 w-full bg-cover bg-center relative z-2", { 'skeleton-bg': _ctx.loadingCard }]),
      style: _normalizeStyle(_ctx.loadingCard ? '' : `background-image: url('${_ctx.imgUrl ? _ctx.imgUrl : _ctx.img}');`)
    }, null, 6),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute h-[76%] w-full top-0 z-3 fleet-card__filter" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.flag)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`fi fi-${_ctx.flag} text-base`)
            }, null, 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["overflow-hidden text-ellipsis whitespace-nowrap", [_ctx.flag ? 'mx-2' : 'mr-2', { skeleton: _ctx.loadingCard }]])
        }, [
          _createElementVNode("small", { title: _ctx.name }, _toDisplayString(_ctx.name), 9, _hoisted_3)
        ], 2)
      ]),
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("tr", _hoisted_5, [
          _createElementVNode("td", {
            class: _normalizeClass(["text-text-alt body-3 text-left w-1/2", { 'skeleton-type': _ctx.loadingCard }])
          }, _toDisplayString(_ctx.type), 3),
          _cache[1] || (_cache[1] = _createElementVNode("td", { class: "text-text-alt body-3 text-right w-auto" }, null, -1))
        ])
      ])
    ]),
    _createVNode(_component_FavouriteButton, {
      onChangeFavStatus: _ctx.changeFavStatus,
      isFav: _ctx.isFavourite,
      "stroke-fav": '#0C111C',
      stroke: 'white',
      "fill-fav": '#0C111C',
      fill: 'none',
      loading: _ctx.loading
    }, null, 8, ["onChangeFavStatus", "isFav", "loading"])
  ]))
}