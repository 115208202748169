import { computed, onUnmounted, watch } from 'vue'
import { circleMarker, layerGroup, Map } from 'leaflet'
import { Router } from 'vue-router'
import categoryStore from '@/map/core/bll/category/category.store'
import { AssetModel } from '@/map/core/bll/category/categoryModel'

const group = layerGroup([])

export default {
  mounted: function (map: Map, router: Router): void {
    map.addLayer(group)

    const addCircle = function (value:string) {
      const asset = categoryStore.state.list.find(a => a.assetCode === value) as AssetModel
      group.clearLayers()
      group.addLayer(circleMarker(asset.latLng, {
        className: 'stroke-blue-500',
        radius: 20,
        fillColor: 'transparent',
        weight: 2,
        interactive: false
      }))
    }
    const subject = computed(function ():string {
      return categoryStore.state.initialized && router.currentRoute.value.query.assetCircle ? router.currentRoute.value.query.assetCircle as string : '-1'
    })

    if (categoryStore.state.initialized && !!router.currentRoute.value.query.assetCircle) {
      addCircle(router.currentRoute.value.query.assetCircle as string)
    } else {
      watch(subject, function (value:string) {
        if (value !== '-1') {
          addCircle(value)
        }
      }, {
        flush: 'post'
      })
    }

    onUnmounted(() => {
      group.remove()
    })
  }
}
