import { AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'

export interface IPlanningFilterHelper{
  findAssetById : (list : AssetUIModel[], assetId : number)=>AssetUIModel
}
export const CreatePlanningFilterHelper = function (): IPlanningFilterHelper {
  return {
    findAssetById: function (list : AssetUIModel[], assetId : number):AssetUIModel {
      return list.find(x => x.id === assetId) || {
        id: -1,
        name: 'not found',
        contractId: -1,
        contractName: '',
        status: '',
        status_color: '',
        image_url: ''
      } as AssetUIModel
    }
  }
}
