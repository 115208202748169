
import { defineComponent, PropType } from 'vue'
import { IdNameModel, Report2Manager, ReportSubscriptionRowAssetModel } from '@/map/core/bll/report/reportManager'

export default defineComponent({
  props: {
    asset: {
      type: Object as PropType<ReportSubscriptionRowAssetModel>,
      required: true
    },
    attributeId: {
      type: Number,
      required: true
    },
    labels: {
      type: Array as PropType<IdNameModel[]>,
      required: true
    }
  },
  methods: {
    getLabel (labels: IdNameModel[], id : number) {
      return labels.find(item => item.id === id) || { id: -1, name: 'not found' }
    },
    onChange (item:ReportSubscriptionRowAssetModel, checked : boolean) {
      item.pending = true
      const reportId = parseInt(this.$route.query.id as string)
      const params = `${reportId}/${this.attributeId}/${item.assetId}`
      Report2Manager.updateSubscription(params, { checked: !checked }).then(() => {
        item.checked = !checked
      }).finally(() => {
        item.pending = false
      })
    }
  }
})
