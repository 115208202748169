import { CategoryZoneModel, GetCategoryZoneModel, ZoneModel } from '@/map/core/bll/zone/zone.model'
import { ApiClient } from '@/map/core/dal/apiClient'

import { zoneMapper } from '@/map/core/bll/zone/zone.mapper'
import { GeoJSON, geoJSON, LayerGroup, PathOptions } from 'leaflet'
import { groupZoneLayers } from '@/map/core/bll/zone/zone.group'

const fetch = function (): Promise< {list: ZoneModel[], group:CategoryZoneModel[]}> {
  return new Promise((resolve, reject) => {
    ApiClient.list<GetCategoryZoneModel>('map/area/maritime').then(result => {
      const group:CategoryZoneModel[] = []
      const list: ZoneModel[] = []
      result.areas.forEach((zone) => {
        const index = group.findIndex(item => item.name === zone.areaType)
        if (index === -1) {
          group.push({ name: zone.areaType })
        }
        list.push(zoneMapper(zone))
        groupZoneLayers.addLayer(geoJSON(JSON.parse(zone.geoJson), {
          pane: 'overlayPane',
          attribution: zone.areaCode,
          style: function () {
            return { color: 'blue', weight: 1, dashArray: [4, 4] }
          }
        }).bindTooltip(zone.areaName, { direction: 'top' }))
      })
      resolve({ group, list })
    }).catch((err:string) => {
      reject(err)
    })
  })
}
const toggleLayer = function (group : LayerGroup, code : string, active : boolean):void {
  group.getLayers().forEach(layer => {
    if (layer instanceof GeoJSON && (layer as GeoJSON).options.attribution === code) {
      layer.setStyle({ className: active ? '' : 'hidden' } as PathOptions)
      layer.remove()
      group.addLayer(layer)
    }
  })
}
export default {
  fetch,
  toggleLayer
}
