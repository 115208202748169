import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

import { computed, toRef } from 'vue'
import { FilterFormatted } from '@/core/model/filter.model'
import { useChartRequestor } from '@/components/analytics/charts/useChartRequestor'
import { useKpiRequestor } from '@/components/analytics/kpi/useKpiRequestor'
import { KpiInfo } from '@/core/model/kpi.model'
import { ChartInfo } from '@/core/model/chart.model'
import ChartCardLoading from '@/components/analytics/charts/card.loading.vue'
import PError from '@/components/analytics/services/previews/error-preview.vue'
import PChartAndGauge from '@/components/analytics/services/previews/chart-and-kpi/chart-and-gauge.kpi.vue'
import PPieAndKpi from '@/components/analytics/services/previews/chart-and-kpi/pie-and-kpi.vue'
import PStandard from '@/components/analytics/services/previews/chart-and-kpi/standard.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'chart-and-kpi-preview',
  props: {
    chart: {},
    kpi: {},
    filtersApplied: {}
  },
  setup(__props: any) {

const props = __props

const chartRequestor = useChartRequestor(props.chart, toRef(props.filtersApplied))
const kpiRequestor = useKpiRequestor(props.kpi, toRef(props.filtersApplied))

const isLoading = computed(() => chartRequestor.isLoading.value || kpiRequestor.isLoading.value)
const isError = computed(() => chartRequestor.isError.value || kpiRequestor.isError.value)

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createBlock(ChartCardLoading, { key: 0 }))
    : (isError.value)
      ? (_openBlock(), _createBlock(PError, {
          key: 1,
          error: _unref(chartRequestor).error.value ?? _unref(kpiRequestor).error.value
        }, null, 8, ["error"]))
      : (_ctx.kpi.type === 'GAUGE' || _ctx.kpi.type === 'SOLID_GAUGE')
        ? (_openBlock(), _createBlock(PChartAndGauge, {
            key: 2,
            "chart-info": _ctx.chart,
            "kpi-info": _ctx.kpi,
            chart: _unref(chartRequestor).values.value,
            kpi: _unref(kpiRequestor).values.value
          }, null, 8, ["chart-info", "kpi-info", "chart", "kpi"]))
        : (_ctx.chart.type === 'PIE')
          ? (_openBlock(), _createBlock(PPieAndKpi, {
              key: 3,
              "chart-info": _ctx.chart,
              "kpi-info": _ctx.kpi,
              chart: _unref(chartRequestor).values.value,
              kpi: _unref(kpiRequestor).values.value
            }, null, 8, ["chart-info", "kpi-info", "chart", "kpi"]))
          : (_openBlock(), _createBlock(PStandard, {
              key: 4,
              "chart-info": _ctx.chart,
              "kpi-info": _ctx.kpi,
              chart: _unref(chartRequestor).values.value,
              kpi: _unref(kpiRequestor).values.value
            }, null, 8, ["chart-info", "kpi-info", "chart", "kpi"]))
}
}

})