
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import LoaderIcon from '@/components/icons/LoaderIcon.vue'
import FormatService from '@/core/services/format.service'
import { ReportAttributeItem } from '@/core/model/report.model'
import { defineComponent, inject, PropType } from 'vue'
import blank from '@/components/icons/fileIcons/blank.vue'
import csv from '@/components/icons/fileIcons/csv.vue'
import doc from '@/components/icons/fileIcons/doc.vue'
import docx from '@/components/icons/fileIcons/docx.vue'
import xls from '@/components/icons/fileIcons/xls.vue'
import xlsx from '@/components/icons/fileIcons/xlsx.vue'
import txt from '@/components/icons/fileIcons/txt.vue'
import tsv from '@/components/icons/fileIcons/tsv.vue'
import pdf from '@/components/icons/fileIcons/pdf.vue'
import { ILoginService } from '@/core/services/authentication/ILogin.service'

export default defineComponent({
  components: {
    DownloadIcon,
    LoaderIcon,
    blank,
    csv,
    doc,
    docx,
    xls,
    xlsx,
    txt,
    tsv,
    pdf
  },
  name: 'ReportInstanceRow.vue',
  setup () {
    return {
      loginService: inject('ILoginService') as ILoginService
    }
  },
  data () {
    return {
      isInternalUser: false
    }
  },
  async created () {
    this.isInternalUser = await this.loginService.IsInternalUser()
  },
  props: {
    reportId: { type: Number },
    item: { type: Object as PropType<ReportAttributeItem>, required: true }
  },
  methods: {
    onDelete (reportId: string, instanceId: number) {
      if (confirm(`Delete report ${reportId}, instance ${instanceId}?`)) {
        this.$emit('delete', { reportId, instanceId })
      }
    },
    download () {
      this.$emit('download')
    },
    formatDate (date: string) {
      return FormatService.formatDate(date)
    },
    periodClass (period: string) {
      switch (period) {
        case 'Daily':
          return 'border-red-500 text-red-500 bg-opacity-30'
        case 'Weekly':
          return 'border-blue-500 text-blue-500 bg-opacity-30'
        case 'Monthly':
          return 'border-cyan-500 text-cyan-500 bg-opacity-30'
        case 'Quarterly':
          return 'border-yellow-500 text-yellow-500 bg-opacity-30'
        case 'Yearly':
          return 'border-green-500 text-green-500 bg-opacity-30'
        default:
          return ''
      }
    }
  }
})
