import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "eventDelegate",
  class: "h-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timeline_action_bar = _resolveComponent("Timeline-action-bar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_time_line_header_scroll = _resolveComponent("time-line-header-scroll")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_time_line_content = _resolveComponent("time-line-content")!
  const _component_time_line_content_wrapper = _resolveComponent("time-line-content-wrapper")!
  const _component_time_line_page_wrapper = _resolveComponent("time-line-page-wrapper")!
  const _component_timeline_popover = _resolveComponent("timeline-popover")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_toggle_scrollbar = _resolveDirective("toggle-scrollbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_time_line_page_wrapper, { "onUpdate:assetAndContract": _ctx.onAssetAndContract }, {
        default: _withCtx(() => [
          _createVNode(_component_Timeline_action_bar, { contracts: _ctx.contracts }, null, 8, ["contracts"]),
          _createVNode(_component_v_row, {
            class: "position-sticky bg-bg-2",
            style: {"top":"0","z-index":"1"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.filterText,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
                    "hide-details": true,
                    "prepend-inner-icon": "mdi-magnify"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_time_line_header_scroll)
            ]),
            _: 1
          }),
          _createVNode(_component_time_line_content_wrapper, {
            assets: _ctx.assetWithoutPeriodList,
            "onUpdate:assetWithPeriod": _ctx.onAssetWithPeriod
          }, {
            default: _withCtx(() => [
              _createVNode(_component_time_line_content, {
                "filter-text": _ctx.filterText,
                assets: _ctx.assetWithPeriodList
              }, null, 8, ["filter-text", "assets"])
            ]),
            _: 1
          }, 8, ["assets", "onUpdate:assetWithPeriod"])
        ]),
        _: 1
      }, 8, ["onUpdate:assetAndContract"])
    ]),
    (_ctx.toggle)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#end-of-body"
        }, [
          _createElementVNode("div", {
            style: {"position":"fixed","inset":"0","background":"rgba(25, 35, 57,.5)","z-index":"9999","cursor":"pointer"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle =false))
          }, [
            _createVNode(_component_v_card, {
              class: "border border-white",
              style: _normalizeStyle([{"position":"absolute","width":"400px","color":"white"}, _ctx.position])
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_timeline_popover, {
                  "period-id": _ctx.periodId,
                  "range-time-id": _ctx.$route.params.rangeTimeId,
                  "asset-id": _ctx.assetId,
                  "contract-id": _ctx.$route.params.contractId
                }, null, 8, ["period-id", "range-time-id", "asset-id", "contract-id"]), [
                  [_directive_toggle_scrollbar]
                ])
              ]),
              _: 1
            }, 8, ["style"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}