import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "position-relative",
  style: {"height":"45px"}
}
const _hoisted_2 = ["data-popover", "data-redirect", "data-asset-id", "data-period-id", "data-period-start", "data-period-end"]
const _hoisted_3 = ["data-popover", "data-redirect", "data-asset-id", "data-period-id"]
const _hoisted_4 = ["data-popover", "data-redirect", "data-asset-id", "data-period-id"]
const _hoisted_5 = {
  key: 0,
  class: "text-text-alt"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_display_content = _resolveDirective("display-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asset.entries, (period, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        "data-popover": !period.isMissing,
        "data-redirect": period.isMissing,
        "data-asset-id": _ctx.asset.id,
        "data-period-id": period.id,
        "data-period-start": period.start_date,
        "data-period-end": period.end_date,
        class: "periodElement d-flex flex-column ps-1 text-no-wrap",
        style: _normalizeStyle([{"position":"absolute","top":"0","height":"38px","cursor":"pointer","border":"solid 1px white"}, [
              { left : period.draw.offsetWidth+'px' },
              { width : period.draw.lengthWidth +'px'},
              { borderTopLeftRadius: period.draw.isStartCropped ? 'none' : '5px'},
              { borderBottomLeftRadius: period.draw.isStartCropped ? 'none' : '5px'},
              { borderTopRightRadius: period.draw.isEndCropped ? '0px' : '5px'},
              { borderBottomRightRadius: period.draw.isEndCropped ? '0px' : '5px'},
              { borderLeft: period.draw.isStartCropped ? 'none' : 'solid 1px'},
              { borderRight: period.draw.isEndCropped ? 'none' : 'solid 1px'},
              {borderColor: period.status_color },
              {'--statusColor' :  period.status_color}
            ]])
      }, [
        _withDirectives((_openBlock(), _createElementBlock("small", {
          style: {"position":"absolute","top":"0","left":"15px"},
          "data-popover": !period.isMissing,
          "data-redirect": period.isMissing,
          "data-asset-id": _ctx.asset.id,
          "data-period-id": period.id,
          class: "text-uppercase"
        }, [
          _createTextVNode(_toDisplayString(period.status), 1)
        ], 8, _hoisted_3)), [
          [_directive_display_content, { periodWidth : period.draw.lengthWidth}]
        ]),
        (!period.isMissing )
          ? _withDirectives((_openBlock(), _createElementBlock("small", {
              key: 0,
              style: {"position":"absolute","top":"15px","left":"15px"},
              "data-popover": !period.isMissing,
              "data-redirect": period.isMissing,
              "data-asset-id": _ctx.asset.id,
              "data-period-id": period.id
            }, [
              (period.isDisplayReason)
                ? (_openBlock(), _createElementBlock("small", _hoisted_5, " Reason: " + _toDisplayString(period.reason), 1))
                : _createCommentVNode("", true)
            ], 8, _hoisted_4)), [
              [_directive_display_content, { periodWidth : period.draw.lengthWidth}]
            ])
          : _createCommentVNode("", true)
      ], 12, _hoisted_2))
    }), 128))
  ]))
}