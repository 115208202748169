import { reactive } from 'vue'
import { AssetModel, CategoryModel } from '@/map/core/bll/category/categoryModel'
import categoryManager from '@/map/core/bll/category/category.manager'
import { LatLng } from 'leaflet'

export const state = reactive({ list: [] as AssetModel[], group: [] as CategoryModel[], initialized: false, loading: false, error: '' })

const mutation = {
  loading: (value: boolean) => {
    state.loading = value
  },
  success: (result : { list: AssetModel[], group: CategoryModel[] }) => {
    state.list = result.list
    state.group = result.group
    state.initialized = true
  },
  error: function (err: string) {
    state.error = err
  },
  update: function (assetCode: string, value: boolean) {
    state.list = state.list.map(o => {
      return { ...o, checked: o.assetCode === assetCode ? value : o.checked }
    })
  }
}
const effect = {
  fetch: function () {
    mutation.error('')
    mutation.loading(true)
    categoryManager.fetch().then(result => {
      mutation.success(result)
    }).catch(err => {
      mutation.error(err)
    }).finally(() => {
      mutation.loading(false)
    })
  }
}
const action = {
  fetch: function ():void {
    effect.fetch()
  },
  update: function (code: string, value: boolean): void {
    mutation.update(code, value)
  }
}

const getter = {
  noCategory: (): boolean => {
    return state.group.length === 0
  },
  atLeastOneCategory: (): boolean => {
    return state.group.length > 1
  },
  activeItems: (): AssetModel[] => {
    return state.list.filter(asset => asset.checked)
  },
  activeBound: (): LatLng[] => {
    return getter.activeItems().reduce((x: LatLng[], y: AssetModel) => {
      x.push(y.latLng)
      return x
    }, [])
  }
}

export default {
  action,
  getter,
  state
}
