import { Router } from 'vue-router'
import { IConfigPopover } from '@/map/core/service/popover.service'

const assetMouseOut = function (target:HTMLElement, router :Router) {
  const _mouseOut = async function () {
    target.removeEventListener('mouseout', _mouseOut)
    await router.push({ query: { ...router.currentRoute.value.query, assetPopoverAdd: undefined, stepPopoverAdd: undefined } })
  }
  target.addEventListener('mouseout', _mouseOut)
}

const assetClick = function (target:HTMLElement, router :Router, additionalQuery: Record<string, string | undefined>) {
  const _mouseClick = async function () {
    target.removeEventListener('click', _mouseClick)
    await router.push({
      query: { ...router.currentRoute.value.query, ...additionalQuery, flyTo: undefined }
    })
  }
  target.addEventListener('click', _mouseClick)
}

export default {
  getPosition: function (isLeft : boolean, conf: IConfigPopover, router: Router):HTMLElement {
    const fullConf = {
      svg: {
        positionX: isLeft ? ('left:' + (conf.svgDim - conf.demiCircle - conf.offset) * -1) : ('left:' + (conf.demiCircle - conf.offset) * -1),
        positionY: (conf.demiCircle - conf.offset) * -1
      },
      circle: {
        cx: isLeft ? conf.svgDim - (conf.demiCircle) : conf.demiCircle,
        cy: conf.demiCircle
      },
      mask: {
        rect: {
          x: isLeft ? 0 : conf.demiCircle,
          y: conf.demiCircle,
          width: conf.svgDim - conf.demiCircle,
          height: conf.svgDim - conf.demiCircle
        }
      },
      line: {
        x1: isLeft ? 0 : conf.demiCircle,
        y1: isLeft ? conf.svgDim : conf.demiCircle,
        x2: isLeft ? conf.svgDim - conf.demiCircle : conf.svgDim,
        y2: isLeft ? conf.demiCircle : conf.svgDim
      }
    }

    const wrapper = document.createElement('div')
    wrapper.innerHTML = `


  <svg
  style="position:absolute;top: ${fullConf.svg.positionY}px;${fullConf.svg.positionX}px;"
  width="${conf.svgDim}"
  height="${conf.svgDim}"
  >
  <g stroke-width="${conf.stroke}" class="stroke-blue-500">
    <mask id="cut">
        <rect
          x="${fullConf.mask.rect.x}"
          y="${fullConf.mask.rect.y}"
          fill="white"
          height="${fullConf.mask.rect.height}"
          width="${fullConf.mask.rect.width}">
        </rect>
        <circle
          r="${conf.radius}"
          cx="${fullConf.circle.cx}"
          cy="${fullConf.circle.cy}" >
        </circle>
  </mask>
    <circle
      r="${conf.radius}"
      fill="transparent"
      cx="${fullConf.circle.cx}"
      cy="${fullConf.circle.cy}" >
    </circle>
    <line
      x1="${fullConf.line.x1}"
      y1="${fullConf.line.y1}"
      x2="${fullConf.line.x2}"
      y2="${fullConf.line.y2}"
      mask="url(#cut)" >
    </line>
  </g>
  </svg>
  `
    assetMouseOut(wrapper, router)
    assetClick(wrapper, router, conf.query)
    return wrapper
  }
}
