import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-gray-300"
}
const _hoisted_2 = {
  key: 1,
  class: "flex overflow-x-scroll no-scroll"
}
const _hoisted_3 = {
  key: 2,
  class: "flex overflow-x-scroll no-scroll"
}
const _hoisted_4 = { class: "ml-4 inline-block" }

import { computed } from 'vue'
import Tag from '@/components/common/tag/tag.vue'
import TagLoading from '@/components/common/tag/tag.loading.vue'
import { CategoryModel } from '@/core/model/category.model'


export default /*@__PURE__*/_defineComponent({
  __name: 'category-list-tags-header',
  props: /*@__PURE__*/_mergeModels({
    categories: {},
    isLoading: { type: Boolean },
    error: {}
  }, {
    "visibleCategories": { required: true },
    "visibleCategoriesModifiers": {},
  }),
  emits: ["update:visibleCategories"],
  setup(__props: any) {

const props = __props

const visibleCategories = _useModel<boolean[]>(__props, 'visibleCategories')

const isAllActive = computed(() => visibleCategories.value.every(c => c === true))

async function toggleCat (index:number) {
  if (isAllActive.value) {
    visibleCategories.value = props.categories.map((c, i) => i === index)
    return
  }

  visibleCategories.value[index] = !visibleCategories.value[index]
  if (visibleCategories.value.findIndex(c => c) === -1) {
    selectAll()
  }
}

function selectAll () {
  visibleCategories.value = props.categories.map(c => true)
}

return (_ctx: any,_cache: any) => {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error), 1))
    : (_ctx.isLoading && !_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (index) => {
            return _createVNode(TagLoading, { key: index })
          }), 64))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Tag, {
            title: 'All',
            active: isAllActive.value,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (selectAll()))
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", null, "All", -1)
            ])),
            _: 1
          }, 8, ["active"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
            return (_openBlock(), _createBlock(Tag, {
              active: !isAllActive.value && visibleCategories.value[index],
              onClick: ($event: any) => (toggleCat(index)),
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(category.name), 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(category.services.length), 1)
              ]),
              _: 2
            }, 1032, ["active", "onClick"]))
          }), 128))
        ]))
}
}

})