import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "context-group" }
const _hoisted_2 = { class: "activity-wrapper" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "time-indicator" }

import { Activity } from '@/core/model/timeline.model'
import ColoursService from '@/core/services/colours.service'

type IntervalGroup = { startTime: Date, endTime: Date, activities: Array<Activity>, intervals: Array<Date>}

export default /*@__PURE__*/_defineComponent({
  __name: 'day-activities',
  props: {
    activities: {},
    shownActivity: {}
  },
  emits: ["showActivity"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const intervalGroups = props.activities.reduce((accumulator, current) => {
  const currentStartTime = new Date(current.startTime)
  const currentEndTime = new Date(current.endTime)
  const existingGroup = accumulator.find(a => a.startTime < currentEndTime && a.endTime > currentStartTime)
  if (existingGroup === undefined) {
    accumulator.push({
      startTime: currentStartTime,
      endTime: currentEndTime,
      intervals: [
        currentStartTime,
        currentEndTime
      ],
      activities: [
        current
      ]
    })
  } else {
    existingGroup.startTime = existingGroup.startTime < currentStartTime ? existingGroup.startTime : currentStartTime

    existingGroup.endTime = existingGroup.endTime > currentEndTime ? existingGroup.endTime : currentEndTime

    existingGroup.activities.push(current)

    if (existingGroup.intervals.find(i => i.getTime() === currentStartTime.getTime()) === undefined) {
      existingGroup.intervals.push(currentStartTime)
    }
    if (existingGroup.intervals.find(i => i.getTime() === currentEndTime.getTime()) === undefined) {
      existingGroup.intervals.push(currentEndTime)
    }
  }

  return accumulator
}, [] as Array<IntervalGroup>)

function getHours (date: Date) : string {
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
}

function sortIntervals (intervals: Array<Date>): Array<Date> {
  return intervals.sort()
}

function calculateRowSpan (group: IntervalGroup, activity: Activity) {
  const currentStartTime = new Date(activity.startTime)
  const currentEndTime = new Date(activity.endTime)
  return sortIntervals(group.intervals).filter(i => i <= currentEndTime && i >= currentStartTime).length - 1
}

function calculateRowStart (group: IntervalGroup, activity: Activity) {
  const currentStartTime = new Date(activity.startTime)
  return sortIntervals(group.intervals).reduce((acc, interval, index) => {
    // If accumulator is already found, return it
    if (acc !== -1) return acc

    // If target found, return current index
    if (interval.getTime() === currentStartTime.getTime()) return index

    return acc
  }, -1)
}

function showActivity (activity: Activity) {
  const index = props.activities.indexOf(activity)
  emit('showActivity', index)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(intervalGroups), (group, g_index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "slot-group",
        key: g_index
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.activities, (activity, a_index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["activity-slot", {'selected': _ctx.shownActivity === activity }]),
              key: a_index,
              onClick: () => showActivity(activity),
              style: _normalizeStyle({
            '--column-size': (100 / group.activities.length) +'%',
            '--column-index': a_index,
            '--row-span': calculateRowSpan(group, activity),
            '--row-start': calculateRowStart(group, activity),
            backgroundColor: _unref(ColoursService).getColour(activity.name),
            borderColor: _unref(ColoursService).colourLightness(_unref(ColoursService).getColour(activity.name), 0.8)
          })
            }, _toDisplayString(activity.name), 15, _hoisted_3))
          }), 128))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortIntervals(group.intervals), (interval, i_index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i_index,
            class: "interval-slot"
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(getHours(interval)), 1)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}
}

})