
import FleetTabs from '@/components/buttons/FleetTabs.vue'
import ListCardView from '@/components/buttons/ListCardView.vue'
import FleetCard from '@/components/cards/FleetCard.vue'
import * as FleetManager from '@/core/managers/fleet.manager'
import { AssetApiModel, FleetCategoryModel } from '@/core/model/fleet.model'
import { defineComponent } from 'vue'
import Observer from '@/components/Observer.vue'
import Tools from '@/core/services/tools'
import SearchInput from '@/components/SearchInput.vue'
import { useToast } from 'vue-toastification'
import PageTitle from '@/components/titles/PageTitle.vue'

interface FleetData {
  cardView: boolean;
  tabDisplayed: string;
  categoryId: number;
  vessels: AssetApiModel[];
  loadingList: boolean;
  pageIndex: number;
  hasNextPage: boolean;
  observe: boolean;
  error: string;
  search: string;
  debounce?: () => void;
}

export default defineComponent({
  name: 'Assets',
  components: {
    FleetTabs,
    FleetCard,
    ListCardView,
    Observer,
    SearchInput,
    PageTitle
  },
  setup () {
    const toast = useToast()
    return { toast }
  },
  data (): FleetData {
    return {
      cardView: sessionStorage.getItem('cardView')
        ? JSON.parse(sessionStorage.getItem('cardView') as string)
        : true,
      tabDisplayed: '',
      categoryId: 0,
      vessels: [],
      loadingList: false,
      pageIndex: 0,
      hasNextPage: true,
      observe: true,
      error: '',
      search: '',
      debounce: undefined
    }
  },
  created () {
    this.debounce = Tools.debounce(() => {
      this.updateList(false, this.search)
    }, 300)
  },
  methods: {
    loadDetails (entry: IntersectionObserverEntry) {
      if (entry.isIntersecting && this.hasNextPage && this.observe) {
        if (this.search) {
          this.updateList(true, this.search)
        } else {
          this.updateList(true)
        }
        this.toggleObservation()
      }
    },
    displayTabInfos (tab: FleetCategoryModel) {
      this.pageIndex = 0
      this.tabDisplayed = tab.name
      this.categoryId = tab.id
      this.updateList(false)
    },
    changeView ($e: string) {
      if ($e === 'card') {
        this.cardView = true
      } else {
        this.cardView = false
      }
      sessionStorage.setItem('cardView', `${this.cardView}`)
    },
    searchAssets () {
      this.toggleObservation()
      this.pageIndex = 0
      this.vessels = []
      if (this.debounce) {
        this.debounce()
      }
    },
    toggleObservation () {
      this.observe = false
      setTimeout(() => {
        this.observe = true
      }, 300)
    },
    updateList (update: boolean, search?: string) {
      this.loadingList = true
      this.pageIndex = update ? this.pageIndex + 1 : this.pageIndex
      if (!update) {
        this.vessels = []
      }
      this.apiList(update, search)
    },
    apiList (update: boolean, search?: string) {
      FleetManager.Manager.assets(this.categoryId, this.pageIndex, undefined, search).then(
        (response) => {
          if (!response.isError && response.data) {
            this.vessels = update
              ? this.vessels.concat(response.data.assets)
              : response.data.assets
            this.hasNextPage = response.data.hasNextPage
            this.loadingList = false
          } else {
            this.error = `${this.$t('fleet.errorMain')}${response.Message}`
            this.toast.error(this.error)
          }
        }
      )
    }
  }
})
