import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'
import { Chart as Highcharts } from 'highcharts-vue'
import BarChart from '@/core/charts/bar.chart.service'
import { ChartOptionsModel } from '@/core/model/chart.model'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'


export default /*@__PURE__*/_defineComponent({
  __name: 'chart.error',
  props: {
    error: {},
    options: {}
  },
  setup(__props: any) {
const props = __props

const chartOptions = computed((): Partial<ChartOptionsModel> | Record<string, never> => {
  const options = BarChart.createChart({ categories: [], id: 0, xAxis: [] }, props.options)
  // options.lang = {
  //   noData: props.error ?? 'Error occured while fetching data'
  // }
  return options
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Highcharts), { options: chartOptions.value }, null, 8, ["options"]))
}
}

})