import { computed, onUnmounted, watch } from 'vue'
import { circleMarker, divIcon, layerGroup, Map, marker, Marker } from 'leaflet'
import { Router } from 'vue-router'
import categoryStore from '@/map/core/bll/category/category.store'
import { groupAssetLayer } from '@/map/core/bll/category/category.group'

export const fleetAssetGroup = layerGroup([])
export default {
  mounted: function (map: Map, router: Router): void {
    map.addLayer(fleetAssetGroup)

    const fleetAsset = function (value :string) {
      fleetAssetGroup.clearLayers()
      if (value !== '-1') {
        groupAssetLayer.getLayers().forEach(layer => {
          if (layer instanceof Marker) {
            if (((layer as Marker).options.attribution as string).split('_')[0] === value) {
              const asset = categoryStore.state.list.find(item => item.assetCode === value)
              if (asset) {
                if (asset.isVessel === true) {
                  fleetAssetGroup.addLayer(marker(asset.latLng,
                    {
                      riseOnHover: true,
                      icon: divIcon({
                        html: `<img data-asset-code="${asset.assetCode}" data-popover-type="asset" src="${require('@/assets/img/vessel/vessel_blue.png')}" alt="${asset.assetName}" style="width: ${asset.markerDim + 'px'} ; height: ${asset.markerDim + 'px'};transform: rotate( ${asset.heading + 'deg'})" >`,
                        className: 'bg-transparent ',
                        iconAnchor: [asset.markerDim / 2, asset.markerDim / 2]
                      })
                    }))
                }

                fleetAssetGroup.addLayer(circleMarker(asset.latLng, {
                  className: 'stroke-blue-500',
                  radius: 20,
                  fillColor: 'transparent',
                  weight: 2,
                  interactive: false
                }))
              }
            }
            if (((layer as Marker).options.attribution as string).split('_')[1] === 'false') {
              fleetAssetGroup.addLayer(layer)
            }
          }
        })
      }
    }

    if (categoryStore.state.initialized && router.currentRoute.value.query.fleetAsset) {
      fleetAsset(router.currentRoute.value.query.fleetAsset as string)
    } else {
      if (!categoryStore.state.initialized) {
        categoryStore.action.fetch()
      }

      const subject = computed(function ():string {
        return categoryStore.state.initialized && router.currentRoute.value.query.fleetAsset ? router.currentRoute.value.query.fleetAsset as string : '-1'
      })

      watch(subject, function (value :string) {
        fleetAsset(value)
      })
    }

    onUnmounted(() => {
      fleetAssetGroup.remove()
    })
  }
}
