import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'
import { Chart as Highcharts } from 'highcharts-vue'
import Gauge from '@/core/charts/gauge.chart.service'
import { ChartModel, ChartOptionsModel } from '@/core/model/chart.model'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'


export default /*@__PURE__*/_defineComponent({
  __name: 'gauge.chart',
  props: {
    values: {},
    options: {}
  },
  setup(__props: any) {

const props = __props
const chartOptions = computed((): Partial<ChartOptionsModel> | Record<string, never> => Gauge.createChart(props.values, props.options))


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Highcharts), { options: chartOptions.value }, null, 8, ["options"]))
}
}

})