import { UserModel } from '@/core/model/user.model'
import { ApiResult, http } from '@/core/services/request.service'

export const Manager = {
  detail (): Promise<ApiResult<UserModel>> {
    return http.request<UserModel>('get', 'Users/me')
      .then((rep: ApiResult<UserModel>) => rep)
      .catch(err => err)
  }
}
