
import { defineComponent, inject } from 'vue'
import { AssetAndContractModel, AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'
import { IAssetAndContractManager } from '@/core/managers/planningFilter/planningFilterManager'
import { IEntriesForMonthManager } from '@/core/managers/entriesForMonth/entriesForMonthManager'
import { ICalendarEntry, IEntriesForMonth } from '@/core/managers/entriesForMonth/entriesForMonthModel'
import periodExpand from '@/views/feat/periodExpand.vue'
import { IEntryManager } from '@/core/managers/entry/entryManager'
import { IAppStore } from '@/core/appStore'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'

export default defineComponent({
  name: 'periodPage',
  emits: [''],
  components: {
    periodExpand
  },
  watch: {
    ready2Expand: {
      immediate: true,
      handler: async function (value:boolean) {
        if (value) {
          this.expandEntryId = parseInt(this.$route.query?.entryId as string)
        }
      }
    },
    '$route.params.assetId': {
      immediate: true,
      handler: async function (value:string) {
        if (value) {
          this.expandEntryId = -1
          this.loading = true
          this.entriesForMonth = await this.entriesForMonthManager.fetch(this.$route.params.contractId as string, value, this.controller.signal)
          this.loading = false
        }
      }
    }
  },
  computed: {
    ready2Expand (): boolean {
      return !this.loadingFilter && !this.loading && !!this.$route.query && !!this.$route.query.entryId
    }
  },
  setup () {
    const controller = new AbortController()
    const appStore = inject('appStore') as IAppStore
    const entryManager = inject('entryManager') as IEntryManager
    const timeLineHelper = inject('timelineHelper') as ITimeLineHelper
    const entriesForMonthManager = inject('entriesForMonthManager') as IEntriesForMonthManager
    const planningFilterManager = inject('planningFilterManager') as IAssetAndContractManager
    return { planningFilterManager, entriesForMonthManager, entryManager, controller, appStore, timeLineHelper }
  },
  data () {
    return { planningFilter: {} as AssetAndContractModel, entriesForMonth: {} as IEntriesForMonth, loading: false, pending: false, loadingFilter: false, expandEntryId: -1 }
  },
  async created () {
    this.loadingFilter = true
    try {
      this.entriesForMonth = this.entriesForMonthManager.init
      this.planningFilter = this.planningFilterManager.init
      this.planningFilter = await this.planningFilterManager.fetch(this.controller.signal)
    } finally {
      this.loadingFilter = false
    }
  },
  beforeUnmount () {
    this.controller.abort()
  },
  methods: {
    getAssetFilter (list : AssetUIModel[], id :number):AssetUIModel {
      return list.find(x => x.id === id) || this.planningFilterManager.assetNotFound
    },
    filterByContract (items : AssetUIModel[], contractId : number): AssetUIModel[] {
      return items.filter(x => x.contractId === contractId)
    },
    entryByStatus (items: ICalendarEntry[]) {
      return items.filter(x => !x.isMissing)
    },
    async onDelete (contractId: string, assetId: string, entryId: number) {
      await this.entryManager.delete(contractId, assetId, entryId, this.controller.signal)
      this.loading = true
      await this.entriesForMonthManager.fetch(contractId, assetId, this.controller.signal)
      this.loading = false
      this.appStore.action.toaster.show('delete success', 'green')
    },
    toggleExpand (entryId:number) {
      this.$router.push({ query: { entryId } })
    }

  }
})
