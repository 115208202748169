import { Router } from 'vue-router'
import { ILoginService } from '@/core/services/authentication/ILogin.service'

export const CreateAppRouter = function (router : Router, loginService : ILoginService): void {
  router.beforeEach(async (to, from, next) => {
    const isInternalUser = await loginService.IsInternalUser()
    if (to.meta && to.meta.credential === 'isInternal') {
      if (isInternalUser) {
        next()
      } else {
        return false
      }
    } else {
      next()
    }
  })
}
