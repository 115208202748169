
import { defineComponent, ref } from 'vue'
import FavouriteButton from '@/components/buttons/FavouriteButton.vue'
import VesselLengthIcon from '@/components/icons/VesselLengthIcon.vue'
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import assets from '@/core/composables/assets.composable'
import manageFavourite from '@/core/composables/favourite.composable'
import { useRoute } from 'vue-router'
import FormatService from '@/core/services/format.service'

export default defineComponent({
  name: 'FleetDetailTitleCard',
  components: {
    FavouriteButton,
    // Compass,
    // SpeedometerIcon,
    VesselLengthIcon,
    // WifiIcon,
    LeftArrowIcon
  },
  props: {
    id: Number,
    isFav: Boolean,
    name: String,
    type: String,
    country: String,
    course: Number,
    lastCourseUpdate: String,
    speed: String,
    length: String,
    imo: String,
    mmsi: String,
    bridgeNumber: String,
    bridgeEmail: String,
    loading: Boolean,
    imgUrl: String
  },
  setup (props) {
    // if (props.type === undefined) throw new Error('Asset has not type.')
    const loa = props.length === undefined ? undefined : +props.length
    const lengthFormatted = FormatService.preciseNumberRound(loa, 1)
    const route = useRoute()
    const assetsImages = assets(props.type!, props.country)
    const manageFav = manageFavourite(+route.params.assetId, +route.params.categoryId, props.loading, props.isFav)
    const isFavourite = ref(manageFav.isFavouriteRef)
    const icon = assetsImages.icon
    const img = assetsImages.img
    const flag = assetsImages.flag
    const changeFavStatus = manageFav.changeFavStatus
    const favLoading = ref(manageFav.loadingRef)
    return { icon, img, flag, changeFavStatus, isFavourite, favLoading, lengthFormatted }
  }
})
