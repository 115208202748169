import { AllDashboardsModel, DashboardModel } from '@/core/model/dashboard.model'
import { ApiResult, http } from '@/core/services/request.service'

export const Manager = {
  detail (id: number): Promise<ApiResult<DashboardModel>> {
    return http.request<DashboardModel>('get', `Dashboards/${id}`)
      .then((rep: ApiResult<DashboardModel>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  },
  list (): Promise<ApiResult<AllDashboardsModel>> {
    return http.request<AllDashboardsModel>('get', 'Dashboards')
      .then((rep: ApiResult<AllDashboardsModel>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }
}
