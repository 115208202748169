
import { defineComponent } from 'vue'
import MonitorIcon from '@/components/icons/MonitorIcon.vue'
import SmartphoneIcon from '@/components/icons/SmartphoneIcon.vue'
import TabletIcon from '@/components/icons/TabletIcon.vue'

export default defineComponent({
  name: 'DashboardCard',
  props: {
    title: String,
    id: Number,
    previewImageUrl: String,
    smartphone: { type: Boolean, default: true },
    tablet: { type: Boolean, default: true },
    laptop: { type: Boolean, default: true },
    loading: Boolean
  },
  components: { SmartphoneIcon, TabletIcon, MonitorIcon },
  data () {
    return { isFav: false }
  }
})
