import { Map } from 'leaflet'
import { Router } from 'vue-router'
import { computed, watch } from 'vue'
import stepPopover from '@/map/views/components/map/step/stepPopover'
import stepStore from '@/map/core/bll/step/step.store'

export default {
  mounted: function (map: Map, router: Router): void {
    if (router.currentRoute.value.query.stepPopoverAdd) {
      router.push({ query: { ...router.currentRoute.value.query, stepPopoverAdd: undefined } })
    }

    const subject = computed(function ():string {
      return stepStore.state.initialized && router.currentRoute.value.query.stepPopoverAdd ? router.currentRoute.value.query.stepPopoverAdd as string : '-1'
    })

    watch(subject, function (value :string):void {
      const element = document.querySelector('[data-panel-type=step]')
      if (element) {
        element.remove()
      }
      if (value !== '-1') {
        const step = stepStore.state.steps[parseInt(value)]
        const marker = (document.querySelector(`[data-step-index='${value}']`) as HTMLElement).parentElement
        if (marker && step) {
          stepPopover.append(marker, step, false, router)
        }
      }
    }, {
      flush: 'post'
    })
  }
}
