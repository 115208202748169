
import { defineComponent, inject } from 'vue'
import Treeselect from 'vue3-treeselect'
import { ContextManager } from '@/core/managers/context.manager'
import { DepartmentModel } from '@/core/model/context.model'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'DepartmentSelect',
  // inject: [ContextManager.name],
  setup () {
    const toast = useToast()
    return {
      toast,
      contextManager: inject(ContextManager.name) as ContextManager
    }
  },
  components: { Treeselect },
  data () {
    return {
      departments: [] as DepartmentModel[],
      modelValue: 0,
      loading: true,
      error: ''
    }
  },
  created () {
    this.contextManager.departmentList().then((response) => {
      this.loading = false
      if (!response.isError && response.data) {
        const departments = response.data
        for (const department of departments) {
          if (department.children && department.children.length === 0) {
            delete department.children
          }
        }
        this.departments = departments

        this.contextManager.currentDepartment().then((res) => {
          const currentDepartment = (
            this.departments as DepartmentModel[]
          ).find((department) => department.code === res.data)
          if (currentDepartment) {
            this.modelValue = currentDepartment.id
            this.$emit('departmentChanged', currentDepartment)
          }
        })
      } else {
        this.error = this.$t('allReports.error', {
          error: response.Message
        })
        this.toast.error(this.error)
      }
    })
  },
  methods: {
    normalizer (node: DepartmentModel) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    departmentSelection ($event: number) {
      const currentDepartment = (this.departments as DepartmentModel[]).find(
        (department) => department.id === $event
      )

      this.$nextTick(() => {
        const control = document.querySelectorAll(
          '.vue-treeselect__single-value'
        )
        if (control && control[0]) {
          control[0].classList.add('line-height-value-selected')
        }
      })
      if (currentDepartment) {
        this.contextManager.declareDepartmentCode(
          currentDepartment.code as string
        )
        this.$emit('departmentChanged', currentDepartment)
      }
    },
    rotateArrow () {
      const arrowContainer = document.querySelectorAll(
        '.vue-treeselect__control-arrow-container'
      )
      arrowContainer[0].classList.toggle('rotate-x-180')
    }
  }
})
