import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { myCustomLightTheme } from '@/plugins/themeLight.mjs'
import { myCustomDarkTheme } from '@/plugins/themeDark.mjs'
import * as components from 'vuetify/components'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VNumberInput } from 'vuetify/labs/VNumberInput'

export const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'myCustomDarkTheme',
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 2,
      darken: 2
    },
    themes: {
      myCustomLightTheme,
      myCustomDarkTheme
    }
  },
  components: {
    ...components,
    VDateInput,
    VTimePicker,
    VNumberInput
  },
  defaults: {
    VCard: { color: 'danger' },
    VTabs: { color: 'primary' },
    VTab: { class: 'text-capitalize font-weight-bold text-subtitle-1' },
    VBtn: { flat: true, style: 'font-weight:normal;letter-spacing:0' },
    VSelect: { variant: 'outlined', density: 'compact', bgColor: 'surface-light', color: 'primary', itemValue: 'id', itemTitle: 'name', menuIcon: 'mdi-chevron-down', transition: 'scale-transition' },
    VTextField: { variant: 'outlined', density: 'compact', bgColor: 'surface-light', color: 'primary' }
  }
})
