import { Map } from 'leaflet'
import { onUnmounted } from 'vue'
import zoneStore from '@/map/core/bll/zone/zone.store'
import { groupZoneLayers } from '@/map/core/bll/zone/zone.group'

export default {
  mounted: function (map: Map): void {
    map.addLayer(groupZoneLayers)

    if (!zoneStore.state.initialized) {
      zoneStore.action.fetch()
    }

    onUnmounted(() => {
      groupZoneLayers.remove()
    })
  }

}
