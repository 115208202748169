import { ApplicationInsights, IEventTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ILogger } from '@/core/services/loggers/ilogger.service'

export class LoggerService implements ILogger {
  private _appInsights: ApplicationInsights

  constructor (connectionString: string, customTelemetries: Record<string, string>) {
    this._appInsights = this.setupAppInsights(connectionString)

    for (const recordKey in customTelemetries) {
      this.addTelemetry(recordKey, customTelemetries[recordKey])
    }
  }

  private setupAppInsights (connectionString: string) : ApplicationInsights {
    const instrumentationKey = connectionString?.substring(
      connectionString.indexOf('=') + 1,
      connectionString.lastIndexOf(';')
    )
    const appInsights = new ApplicationInsights({
      config: {
        connectionString,
        instrumentationKey,
        enableAutoRouteTracking: true,
        loggingLevelConsole: 1,
        autoTrackPageVisitTime: true
      }
    })
    // todo change env variable
    if (process.env.VUE_APP_URL !== 'http://localhost:5000/') {
      appInsights.loadAppInsights()
      appInsights.trackPageView()
    }

    return appInsights
  }

  updateClientDepartmentContext (ClientDepartmentCode: string) {
    this.addTelemetry('context', ClientDepartmentCode)
  }

  // setUserId (userId: string): void {
  //   this.appInsights.setAuthenticatedUserContext(userId)
  // }

  // clearUserId (): void {
  //   this.appInsights.clearAuthenticatedUserContext()
  // }

  private addTelemetry (key: string, value?: string): void {
    const telemetry = (envelope: ITelemetryItem) => {
      if (envelope.data === undefined) throw new Error('Unhandle null data in telemetry.')
      envelope.data[key] = value
    }
    this._appInsights.addTelemetryInitializer(telemetry)
  }

  logPageView (name?: string, uri?: string): void {
    this._appInsights.trackPageView({ name, uri })
  }

  logEvent (event: IEventTelemetry, customProperties?: { [key: string]: any }): void {
    this._appInsights.trackEvent(event, customProperties)
  }
}
