
import { defineComponent, inject, PropType } from 'vue'
import timeLineRowLeft from '@/views/feat/timeLineRowLeft.vue'
import timeLineRowRight from '@/views/feat/timeLineRowRight.vue'
import { AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'

export default defineComponent({
  name: 'TimeLineContent',
  components: {
    timeLineRowLeft,
    timeLineRowRight
  },
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  },
  props: {
    filterText: {
      type: String,
      required: true
    },
    assets: {
      type: Array as PropType<AssetUIModel[]>,
      required: true
    }
  },
  directives: {
    scroll: {
      mounted (element: HTMLElement) {
        const _trigger = document.getElementById('headerScroll')
        if (_trigger) {
          element.style.transform = `translateX(${(_trigger as HTMLElement).scrollLeft * -1}px)`
          _trigger.addEventListener('scroll', function (e) {
            element.style.transform = `translateX(${(e.target as HTMLElement).scrollLeft * -1}px)`
          })
        }
      }
    }
  },
  methods: {
    filter (filterText:string, list : AssetUIModel[]) {
      if (filterText === '') {
        return list
      } else {
        return list.filter(x => x.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
      }
    }
  }
})
