import { Map } from 'leaflet'
import { Router } from 'vue-router'
import { computed, watch } from 'vue'
import stepPopover from '@/map/views/components/map/step/stepPopover'
import stepStore from '@/map/core/bll/step/step.store'

export default {
  mounted: function (map: Map, router: Router): void {
    const subject = computed(function ():string {
      return stepStore.state.initialized && router.currentRoute.value.query.staticStep ? router.currentRoute.value.query.staticStep as string : '-1'
    })

    watch(subject, function (value :string) {
      const element = document.querySelector('[data-panel-type=static]')
      if (element) {
        (element as HTMLElement).remove()
      }

      if (value !== '-1') {
        const step = stepStore.state.steps[parseInt(value)]
        const marker = (document.querySelector(`[data-step-index='${value}']`) as HTMLElement).parentElement
        if (marker && step) {
          stepPopover.append(marker, step, true, router)
        }
      }
    }, {
      flush: 'post'
    })
  }
}
