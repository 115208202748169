
import { defineComponent } from 'vue'
import MenuRight from '@/map/views/components/menuRight.vue'
import vesselDetail from '@/map/views/components/drawers/left/vesselDetail/detail.card.vue'
import toaster from '@/map/views/components/misc/toaster.vue'
import loader from '@/map/views/components/misc/loader.vue'
import mapMaker from '@/map/core/service/map.maker'
import mapEventDelegation from '@/map/core/service/map.eventDelegation'
import tilesComposable from '@/map/views/composables/tiles.composable'
import fetchConcessionComposable from '@/map/views/composables/fetchConcession.composable'
import fetchZoneComposable from '@/map/views/composables/fetchZone.composable'
import fetchAssetComposable from '@/map/views/composables/fetchAsset.composable'
import selectedCircleComposable from '@/map/views/composables/selectedCircle.composable'
import selectedBlueComposable from '@/map/views/composables/selectedSetBlue.composable'
import flyBoundComposable from '@/map/views/composables/flyBound.composable'
import fetchStepComposable from '@/map/views/composables/fetchStep.composable'
import popoverAssetComposable from '@/map/views/composables/popoverAsset.composable'
import popoverStepComposable from '@/map/views/composables/popoverStep.composable'
import popoverStaticComposable from '@/map/views/composables/popoverStatic.composable'
import flyToComposable from '@/map/views/composables/flyTo.composable'

export default defineComponent({
  inject: ['GStore'],
  components: {
    MenuRight,
    vesselDetail,
    toaster,
    loader
  },
  data () {
    return { mapInitialized: false }
  },
  setup () {
    return { mapId: 'mapPage1' }
  },
  mounted () {
    const map = mapMaker.init(this.mapId)
    mapEventDelegation.mounted((map.getPane('markerPane') as HTMLElement), this.$router)
    this.mapInitialized = true
    tilesComposable.mounted(map)
    flyBoundComposable.mounted(map, this.$router)
    flyToComposable.mounted(map, this.$router)
    fetchConcessionComposable.mounted(map)
    fetchZoneComposable.mounted(map)
    fetchAssetComposable.mounted(map)
    fetchStepComposable.mounted(map, this.$router)
    selectedCircleComposable.mounted(map, this.$router)
    selectedBlueComposable.mounted(map, this.$router)
    popoverAssetComposable.mounted(map, this.$router)
    popoverStepComposable.mounted(map, this.$router)
    popoverStaticComposable.mounted(map, this.$router)
  }
})
