import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      icon: "mdi-chevron-left",
      variant: "text",
      density: "comfortable",
      class: "me-1",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onIncrementMonth(-1)))
    }),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_v_btn, {
      icon: "mdi-chevron-right",
      variant: "text",
      density: "comfortable",
      class: "ms-1",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onIncrementMonth(1)))
    })
  ], 64))
}