import { Router } from 'vue-router'

export default {
  mounted: function (pane :HTMLElement, router : Router):void {
    pane.addEventListener('mouseover', async function (e: Event) {
      const img = e.target as HTMLImageElement

      if (img instanceof HTMLImageElement && img.dataset.popoverType === 'asset' && router.currentRoute.value.query.fetch !== img.dataset.assetCode) {
        await router.push({
          query: {
            ...router.currentRoute.value.query,
            assetPopoverAdd: img.dataset.assetCode,
            stepPopoverAdd: undefined
          }
        })
      }
      // todo add mouse over after fetch
      if (img instanceof HTMLImageElement && img.dataset.popoverType === 'step' && router.currentRoute.value.query.staticStep !== img.dataset.stepIndex) {
        await router.push({
          query: {
            ...router.currentRoute.value.query,
            stepPopoverAdd: img.dataset.stepIndex,
            assetPopoverAdd: undefined
          }
        })
      }
    })
  }
}
