import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"height":"250px","overflow":"hidden"},
  class: "d-flex justify-center items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "pb-3"
}
const _hoisted_3 = { class: "ps-4 d-flex text-uppercase align-center" }
const _hoisted_4 = { class: "flex-grow-1 font-weight-bold" }
const _hoisted_5 = { class: "ps-4 d-flex text-uppercase align-center" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "d-flex justify-space-between flex-wrap mt-2" }
const _hoisted_10 = {
  class: "text-text-alt",
  style: {"font-size":".95em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, { indeterminate: "" })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(this.entry.status), 1),
          _createVNode(_component_v_btn, { icon: "mdi-close-circle-outline" })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("small", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.timeLineHelper.formatDate('%m/%d/%Y (%H:%M)', this.entry.startDate)) + " ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mx-1" }, "-", -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.timeLineHelper.formatDate('%m/%d/%Y (%H:%M)',this.entry.endDate )), 1)
          ]),
          _createVNode(_component_v_btn, {
            color: "primary",
            variant: "flat",
            density: "comfortable",
            "append-icon": "mdi-file-edit-outline",
            to: { name : 'period', params : { contractId: _ctx.contractId, assetId: _ctx.assetId, periodId: _ctx.periodId }, query : { entryId :this.entry.id}},
            class: "me-3"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Edit")
            ])),
            _: 1
          }, 8, ["to"])
        ]),
        _createVNode(_component_v_card_text, { class: "d-flex flex-column pb-0" }, {
          default: _withCtx(() => [
            _cache[3] || (_cache[3] = _createElementVNode("div", {
              class: "text-text-alt mt-2",
              style: {"font-size":".95em"}
            }, "Project", -1)),
            _createElementVNode("div", null, _toDisplayString(_ctx.project(this.entry.projectList,this.entry.projectId).name), 1),
            _cache[4] || (_cache[4] = _createElementVNode("div", {
              class: "text-text-alt mt-2",
              style: {"font-size":".95em"}
            }, "Reason", -1)),
            _createElementVNode("div", null, _toDisplayString(this.entry.reasonName), 1),
            (this.entry.isOn)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", {
                    class: "text-text-alt mt-2",
                    style: {"font-size":".95em"}
                  }, "Rate applied", -1)),
                  (this.entry.dailyRate)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(this.entry.dailyRate) + "%", 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, "0%"))
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.entry.robList, (rob, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "d-flex flex-column",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(rob.product), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(rob.quantity), 1),
                    _createElementVNode("span", null, _toDisplayString(rob.unit), 1)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]))
}