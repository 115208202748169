import { timeDay, timeFormat, timeMonth, utcDay } from 'd3'

export interface ITimeLineCol{
  date: Date
  title : string
  width: number
}
export interface ITimeLineTH{
  attributeMonth: string
  attributeYear: string
  isBorderLeft: boolean
  title : string
  width: number
}
export interface ITimeLineInfo {
  firstDate: Date
  lastDate : Date
  totalYear: number
  length : number
}
export interface ITimeLineHelper {
  colWith : number
  wrapperWidth : number
  timelineInfo: ITimeLineInfo,
  month : {id : string, name : string}[]
  year : {id : string, name : string}[]
  scrollTo: (periodId: string)=> number,
  parseDate: (input:string) =>Date | null
  formatDate: (strFormat : string, input?: Date)=>string
  html : {
    generateCol: ()=>ITimeLineCol[],
    generateTH: ()=>ITimeLineTH[]
  }
}

export const CreateTimelineHelper = function (addYearBefore: number, addYearAfter: number): ITimeLineHelper {
  const now = new Date()
  const start = timeMonth.floor(new Date(now.getFullYear() - addYearBefore, now.getMonth(), now.getDate()))
  const end = utcDay.offset(timeMonth.ceil(new Date(now.getFullYear() + addYearAfter, now.getMonth(), now.getDate())), -1)
  const count = timeDay.count(start, end)
  const colWith = 18
  const wrapperWidth = count * colWith
  const months = timeMonth.range(start, end)
  const formatA = timeFormat('%Y-%m')
  const formatB = timeFormat('%B %Y')
  const formatD = timeFormat('%B')
  const formatE = timeFormat('%Y')

  const _timelineInfo = {
    totalYear: addYearBefore + addYearAfter,
    firstDate: start,
    lastDate: end,
    length: count
  }

  return {
    html: {
      generateTH: function ():ITimeLineTH[] {
        const rowTH: ITimeLineTH[] = []
        for (let i = 0; i <= _timelineInfo.length; i++) {
          const incrementDate = timeDay.offset(_timelineInfo.firstDate, i)
          rowTH.push({
            attributeMonth: incrementDate.getDate() === 1 ? formatA(incrementDate) : 'false',
            attributeYear: incrementDate.getDate() === 1 ? formatE(incrementDate) : 'false',
            isBorderLeft: incrementDate.getDate() === 1,
            title: incrementDate.getDate() === 15 ? formatB(incrementDate) : '',
            width: colWith
          })
        }
        return rowTH
      },
      generateCol: function ():ITimeLineCol[] {
        const rowCol: ITimeLineCol[] = []
        const format = timeFormat('%d')
        for (let i = 0; i <= _timelineInfo.length; i++) {
          const incrementDate = timeDay.offset(_timelineInfo.firstDate, i)
          rowCol.push({
            date: incrementDate,
            title: format(incrementDate),
            width: colWith
          })
        }
        return rowCol
      }
    },
    // 2024-10-18 11:32:00
    parseDate: function (input:string):Date | null {
      return input ? new Date(input) : null
    },
    formatDate: function (strFormat : string, input?: Date): string {
      if (input) {
        const format = timeFormat(strFormat)
        return format(input)
      } else {
        return 'no date'
      }
    },
    scrollTo: function (periodId: string):number {
      const temp = periodId.split('-')
      const f = new Date(parseInt(temp[0]), parseInt(temp[1]) - 1, 1)
      return timeDay.count(start, f) * colWith
    },
    colWith,
    wrapperWidth,
    timelineInfo: _timelineInfo,
    month: months.reduce((list:{id : string, name : string}[], row) => {
      list.push({
        id: formatA(row),
        name: formatD(row)
      })
      return list
    }, []),
    year: months.reduce((list:{id : string, name : string}[], row) => {
      const _exist = list.find(x => x.id === formatE(row))
      if (!_exist) {
        list.push({
          id: formatE(row),
          name: formatE(row)
        })
      }
      return list
    }, [])
  }
}
