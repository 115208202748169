import { ChartInfo, ChartModel } from '@/core/model/chart.model'
import { FilterFormatted } from '@/core/model/filter.model'
import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue'
import { Manager as ChartManager } from '@/core/managers/chart.manager'
import { useToast } from 'vue-toastification'

export function useChartRequestor (chart: ChartInfo, filtersApplied: Ref<FilterFormatted[]>) {
  const toast = useToast()
  const values = ref<ChartModel>()
  const error = ref<string>()
  const isQueryLoading = ref<boolean>(true)
  const filtersFiltered = computed(() => filtersApplied.value.filter(f => chart.compatibleFilters.findIndex(cf => cf === f.id) > -1))
  const isLoading = computed(() => isQueryLoading.value || filtersFiltered.value.find(f => f.isLoading) !== undefined)
  const isError = computed(() => !isLoading.value && error.value !== undefined && error.value.length > 0)
  let controller = new AbortController()
  let previousFilters = ''

  onMounted(() => setTimeout(() => { calculateChart() }, 500))
  onUnmounted(() => controller.abort())
  watch(filtersApplied.value, calculateChart)

  return { values, error, isLoading, isError }

  async function calculateChart () {
    if (filtersFiltered.value.find(f => f.isLoading) || JSON.stringify(filtersFiltered.value) === previousFilters) {
      return
    }

    // didn't found another way to store the actual value of appliedFilter without passing the ref proxy object to previousFilters variable
    previousFilters = JSON.stringify(filtersFiltered.value)

    isQueryLoading.value = true
    values.value = undefined
    error.value = undefined

    try {
      controller.abort()
      controller = new AbortController()

      const response = await ChartManager.detail(chart.id, filtersFiltered.value, controller.signal)

      if (response.status === 200) {
        values.value = response.data
      } else if (response.code !== 'ERR_CANCELED') {
        error.value = response.Message ?? 'Error occured while fetching data'
        toast.error(error.value)
      }
    } catch (err) {
      error.value = err as string
    }
    isQueryLoading.value = false
  }
}
