import popoverService, { IConfigPopover } from '@/map/core/service/popover.service'
import { AssetModel } from '@/map/core/bll/category/categoryModel'
import { duration } from '@/map/core/utilities'
import { Router } from 'vue-router'

const _html = function (asset: AssetModel): HTMLElement {
  const wrapper = document.createElement('div')

  let str = `<div class="flex">
    <div class="p-1 text-slate-300 whitespace-nowrap">
      <div>
        <span  class=" text-xl text-white font-bold mr-5">${asset.assetName}</span>`

  if (asset.isVessel && asset.isUnderContract) {
    str += `<i class="fa fa-tower-cell mr-2" ></i>${duration(asset.lastPosition)}`
  }
  str += `</div>
   <div>${asset.assetTypeName}</div>
  `
  if (asset.isVessel && asset.isUnderContract) {
    str += `<div>
      <i class="fa-regular fa-gauge-high me-1"></i>
      ${asset.speed.toFixed(2)} kn
      <i class="fa-regular fa-circle-location-arrow mx-1"></i>
      ${asset.heading.toFixed(2)}°
    </div>`
  }

  str += `</div>
    <img src="${asset.assetImg}" alt="${asset.assetName}" class="rounded-r-md object-cover" style="width: 120px; height: 75px"></div>`

  wrapper.innerHTML = str
  return wrapper
}
// @ts-ignore
export default {
  append: function (anchor : HTMLElement, asset : AssetModel, router : Router):void {
    const conf: IConfigPopover = {
      offset: (asset.markerDim / 2),
      html: _html(asset),
      radius: asset.radius,
      stroke: asset.stroke,
      demiCircle: asset.radius + (asset.stroke / 2),
      svgDim: asset.svgDim,
      query: asset.query
    }
    popoverService.append(anchor, conf, 'asset', router)
  }
}
