

import { defineComponent } from 'vue'
import checkboxRow from '@/map/views/components/drawers/right/category/category.row.vue'
import categoryStore from '@/map/core/bll/category/category.store'
import categoryManager from '@/map/core/bll/category/category.manager'
import { groupAssetLayer } from '@/map/core/bll/category/category.group'

export default defineComponent({
  components: {
    checkboxRow
  },
  setup () {
    return { state: categoryStore.state }
  },
  computed: {
    noCategory: categoryStore.getter.noCategory,
    atLeastOneCategory: categoryStore.getter.atLeastOneCategory
  },
  methods: {
    update (args: { code: string, value : boolean }) {
      categoryStore.action.update(args.code, args.value)
      categoryManager.toggleLayer(groupAssetLayer, args.code, args.value)
      this.$router.push({ query: { popover: 'advancedFilterPopover' } })
    }
  }

})

