import { ApiResult, http } from '@/core/services/request.service'
import { FiltersSettings, FleetSettings, PublicSettings } from '@/core/model/settings.model'
import store from '@/store'

export const Manager = {
  getPublicSettings (): Promise<ApiResult<PublicSettings>> {
    return http.request<PublicSettings>('get', 'Settings')
      .then((rep: ApiResult<PublicSettings>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  },
  fleetSettings (): Promise<ApiResult<FleetSettings>> {
    const fleetSettings = {
      defaultImage_Url: 'Avatar-Default-NoVessel',
      defaultIcon_Url: 'unknown-vessel',
      assetTypeSettings: [
        {
          code: 'FSIV',
          name: 'FSIV',
          icon_Url: 'high-speed-craft',
          image_Url: 'Avatar-Default-FSIV'
        },
        {
          code: 'PSV',
          name: 'PSV',
          icon_Url: 'tug',
          image_Url: 'Avatar-Default-PSV'
        },
        {
          code: 'PLSV',
          name: 'PLSV',
          icon_Url: 'tug',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'TUG',
          name: 'TUG',
          icon_Url: 'tug',
          image_Url: 'Avatar-Default-TUG'
        },
        {
          code: 'AHTS',
          name: 'AHTS',
          icon_Url: 'tug',
          image_Url: 'Avatar-Default-AHTS'
        },
        {
          code: 'AHT',
          name: 'AHT',
          icon_Url: 'tug',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'CREW',
          name: 'CREW',
          icon_Url: 'passenger-ship',
          image_Url: 'Avatar-Default-CREWBOAT'
        },
        {
          code: 'W2W',
          name: 'W2W',
          icon_Url: 'passenger-ship',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'Cruise Ship',
          name: 'Cruise Ship',
          icon_Url: 'passenger-ship',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'Solas Yacht',
          name: 'Solas Yacht',
          icon_Url: 'passenger-ship',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'Large CrewBoat',
          name: 'Large CrewBoat',
          icon_Url: 'passenger-ship',
          image_Url: 'Avatar-Default-CREWBOAT'
        },
        {
          code: 'Crew Supply Vessel',
          name: 'Crew Supply Vessel',
          icon_Url: 'passenger-ship',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'IMR',
          name: 'IMR',
          icon_Url: 'tanker',
          image_Url: 'Avatar-Default-TANKER'
        },
        {
          code: 'Barge',
          name: 'Barge',
          icon_Url: 'cargo',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          code: 'ERRV',
          name: 'ERRV',
          icon_Url: 'cargo',
          image_Url: 'Avatar-Default-NoVessel'
        },
        {
          name: 'SSRV',
          code: 'SSRV',
          icon_Url: 'unknown-vessel',
          image_Url: 'Avatar-Default-SSRV'
        },
        {
          name: 'ETV',
          code: 'ETV',
          icon_Url: 'tug',
          image_Url: 'Avatar-Default-ETV'
        },
        {
          name: 'MPSV',
          code: 'MPSV',
          icon_Url: 'cargo',
          image_Url: 'Avatar-Default-MPSV'
        }
      ]
    }
    return http.request<FleetSettings>('get', 'Settings/fleets')
      .then((rep: ApiResult<FleetSettings>) => {
        const settings =
          rep.data && rep.data.assetTypeSettings.length ? rep.data.assetTypeSettings : fleetSettings
        store.dispatch('setFleetSettings', settings)
      })
      .catch(err => err)
  },
  filtersSettings (): Promise<ApiResult<FiltersSettings>> {
    return http.request<FiltersSettings>('get', 'Settings/filters')
      .then((rep: ApiResult<FiltersSettings>) => {
        const settings = rep.data
        store.dispatch('setFiltersSettings', settings)
      })
      .catch(err => err)
  }
}
