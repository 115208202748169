import { control, Map } from 'leaflet'

export default {
  init: function (elementId : string, opts? : {isScaleControl: boolean}):Map {
    const options = { isScaleControl: true, ...opts }

    const map = new Map(elementId, {
      attributionControl: false,
      zoomControl: false,
      center: [43.279724, 6.325032],
      zoom: 3,
      minZoom: 2,
      worldCopyJump: true
    })
    map.createPane('concessionPane', map.getPane('tilePane'))
    const layers = '.leaflet-overlay-pane, .leaflet-concession-pane'

    map.on('zoomstart', function () {
      document.querySelectorAll(layers).forEach(elem => {
        (elem as HTMLElement).style.opacity = '0'
      })
    })
    map.on('zoomend', function () {
      document.querySelectorAll(layers).forEach(elem => {
        (elem as HTMLElement).style.opacity = '1'
      })
    })

    control.zoom({ position: 'bottomleft' }).addTo(map)

    if (options.isScaleControl) {
      control.scale({ position: 'bottomright', imperial: true }).addTo(map)
    }

    return map
  }
}
