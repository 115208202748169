// @ts-ignore
import { DateTime } from 'luxon'

export const delay = async function (value : number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve()
    }, value)
  })
}

export function displayDate (date: string):string {
  const dd = (new Date(date).getDate()).toString().padStart(2, '0')
  const mm = (new Date(date).getMonth() + 1).toString().padStart(2, '0')
  const yyyy = new Date(date).getFullYear()
  const minute = new Date(date).getMinutes()
  const second = new Date(date).getSeconds()
  return yyyy + '-' + mm + '-' + dd + '-' + minute + ':' + second
}

export function isObjectEmpty (object: Record<string, unknown>): boolean {
  for (const property in object) {
    if (object[property]) { return false }
  }
  return true
}

export function toggle (selector : string, hide : boolean):void {
  document.querySelectorAll(selector).forEach(elem => {
    (elem as HTMLElement).style.display = hide ? 'none' : 'block'
  })
}

export type Immutable<T> = {
  readonly [K in keyof T]: Immutable<T[K]>;
}
export const duration = function (lastPosition: string):string {
  if (lastPosition !== '-1') {
    const start = DateTime.fromISO(lastPosition, { zone: 'UTC' })
    const dt = DateTime.now()
    const diffMinutes = dt.diff(start, 'minutes')
    const diffHours = dt.diff(start, 'minutes')
    const obj = dt.diff(start, ['days', 'hours', 'minutes']).toObject()
    if (diffMinutes.as('minutes') > 60) {
      if (diffHours.as('hours') > 24) {
        return `${obj.days} d`
      } else {
        return `${obj.hours} h`
      }
    } else {
      return `${Math.floor(diffMinutes.as('minutes'))} min`
    }
  } else {
    return ''
  }
}
export const sticky = function (key:string, id : string):void {
  const target = document.getElementById(`target_${key}_${id}`)
  const element = document.getElementById(`element_${key}`)
  if (target && element) {
    const posY = `top:${window.pageYOffset + (target.getBoundingClientRect().bottom)}px`
    const posX = `right:${document.body.clientWidth - target.getBoundingClientRect().right}px`
    element.setAttribute('style', `${element.getAttribute('style')};${posX};${posY}`)
    element.classList.remove('invisible')
  }
}
