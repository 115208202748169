
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import { defineComponent } from 'vue'
import blank from '@/components/icons/fileIcons/blank.vue'

export default defineComponent({
  components: {
    DownloadIcon,
    blank
  }
})
