
import ReportsListMenuShell from '@/components/buttons/ReportsListMenuShell.vue'
import ReportListByServiceCategory from '@/components/reports/categories/ServiceCategoryList.report.vue'
import ReportListByServiceCategoryLoading from '@/components/reports/categories/ReportListByServiceCategory.loading.vue'
import ReportManager from '@/core/managers/report.manager'
import { ReportServiceCategory } from '@/core/model/report.model'
import { defineComponent, PropType, ref, Ref } from 'vue'
import { useToast } from 'vue-toastification'
import { AssetApiModel } from '@/core/model/fleet.model'

export default defineComponent({
  components: {
    ReportsListMenuShell,
    ReportListByServiceCategoryLoading,
    ReportListByServiceCategory
  },
  name: 'FleetReportTab',
  props: {
    asset: { type: Object as PropType<AssetApiModel>, required: true }
  },
  setup () {
    const toast = useToast()
    const error = ref('')
    const categories: Ref<ReportServiceCategory[]> = ref([])
    const loading = ref(true)
    return {
      toast,
      error,
      categories,
      loading
    }
  },
  async created () {
    const serviceReportResponse = await ReportManager.list(true)

    if (serviceReportResponse.isError) { // || assetReportResponse.isError) {
      this.error = this.$t('allReports.error', {
        error: serviceReportResponse.Message
      })
      this.toast.error(this.error)
    }

    if (serviceReportResponse.data) {
      const categories = serviceReportResponse.data.serviceCategories
      this.categories = categories.map(s => {
        s.groups.forEach(g => { g.active = true })
        return ({ ...s, active: true })
      })
    }

    this.loading = false
  }
})
