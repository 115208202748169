import { ConcessionModel, GetConcessionModel } from '@/map/core/bll/concession/concession.model'
import { latLng, LatLng } from 'leaflet'

export function ConcessionMapper (concession : GetConcessionModel): ConcessionModel {
  const multi: LatLng[][] = []

  JSON.parse(concession.geoJson).coordinates.forEach((items:[number, number][] | [number, number][][]) => {
    const t:LatLng[] = []
    items.forEach((item : [number, number] | [number, number][]) => {
      if (Array.isArray(item) && Array.isArray(item[0])) {
        (item as [number, number][]).forEach(sub => {
          t.push(latLng([sub[1], sub[0]]))
        })
      } else {
        t.push(latLng([(item as [number, number])[1], (item as [number, number])[0]]))
      }
    })
    multi.push(t)
  })
  return {
    name: concession.areaName,
    geoJson: concession.geoJson,
    multiPolygon: multi
  }
}
