
import { defineComponent } from 'vue'
import DetailsIcon from '@/components/icons/DetailsIcon.vue'
import FilesIcon from '@/components/icons/FilesIcon.vue'
import HistoryIcon from '@/components/icons/HistoryIcon.vue'

export default defineComponent({
  name: 'AssetDetailTab',
  components: { HistoryIcon, FilesIcon, DetailsIcon },
  props: { name: String },
  computed: {
    icon () {
      switch (this.name) {
        case this.$t('fleet.tabs.history'):
          return 'HistoryIcon'
        case this.$t('fleet.tabs.documents'):
          return 'FilesIcon'
        default:
          return 'DetailsIcon'
      }
    }
  }
})
