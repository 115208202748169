import { FleetCategoriesModel, FleetDetailModel, FleetListModel } from '@/core/model/fleet.model'
import { ApiResult, http } from '@/core/services/request.service'
import { AxiosPromise } from 'axios'
import { TimeSheet } from '@/core/model/timeline.model'

export const Manager = {
  categories (): Promise<ApiResult<FleetCategoriesModel>> {
    return http.request<FleetCategoriesModel>('get', 'Fleet/categories')
      .then((rep: ApiResult<FleetCategoriesModel>) => rep)
      .catch(err => err)
  },
  assets (categoryId: number, index = 0, size?: number, search?: string): Promise<ApiResult<FleetListModel>> {
    let request = `Fleet/categories/${categoryId}/assets?pageIndex=${index}`
    if (search) request += `&search=${search}`
    if (size) request += `&pageSize=${size}`

    return http.request<FleetListModel>('get', request)
      .then((rep: ApiResult<FleetListModel>) => rep)
      .catch(err => err)
  },
  detail (categoryId: number, assetId: number): Promise<ApiResult<FleetDetailModel>> {
    return http.request<FleetDetailModel>('get', `Fleet/categories/${categoryId}/assets/${assetId}`)
      .then((rep: ApiResult<FleetDetailModel>) => rep)
      .catch(err => err)
  },
  manageFavourite (categoryId: number, assetId: number, favourite: boolean): AxiosPromise {
    const favouriteMethod = favourite ? 'post' : 'delete'
    return http.emptyResponseRequest(favouriteMethod, `Fleet/categories/${categoryId}/assets/${assetId}/favorite`)
  },
  timesheet (categoryId: number, assetId: number, period: number): Promise<ApiResult<TimeSheet>> {
    return http.request<any>('get', `Fleet/categories/${categoryId}/assets/${assetId}/TimeSheet/${period}`)
      .then((rep: ApiResult<any>) => rep)
      .catch(err => err)
  }

}
