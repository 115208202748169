<template>
  <div class="flex w-full gap-4">
    <div v-for="i in 2" :key="i">
      <div>
        <div class="skeleton"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .skeleton {
    @include skeleton(13px, 12px, 60px);
  }
</style>
