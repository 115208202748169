import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "flex w-full h-full" }
const _hoisted_3 = { class: "text-left w-full h-full relative" }
const _hoisted_4 = { class: "body body-2 body-alt whitespace-nowrap" }

import AnalyticsIcon from '@/components/icons/AnalyticsIcon.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'error-preview',
  props: {
    error: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, [
          _cache[0] || (_cache[0] = _createElementVNode("span", null, [
            _createElementVNode("h3", null, "Error")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.error ?? 'Error while fetching data'), 1)
          ])
        ])
      ])
    ])
  ]))
}
}

})