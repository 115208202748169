import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Teleport as _Teleport } from "vue"
import _imports_0 from '@/assets/img/logo/Favicon-Marinsights-2022.png'


const _hoisted_1 = { class: "h-full flex flex-col w-full layout-container" }
const _hoisted_2 = { class: "bg-nav border-b border-bg-2" }
const _hoisted_3 = { class: "flex items-center pt-3 pb-2.5 px-4 md:px-12 font-normal text-lg max-w-[1800px] mx-auto relative w-full justify-between z-1001" }
const _hoisted_4 = { class: "flex items-center lg:justify-between w-full" }
const _hoisted_5 = { class: "hidden lg:flex" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "me-2" }
const _hoisted_8 = { class: "rounded-full p-2 bg-bg-2 hover:bg-bg-3 w-fit" }
const _hoisted_9 = { style: {"position":"relative"} }
const _hoisted_10 = {
  key: 0,
  style: {"position":"absolute","right":"0","top":"-10px"}
}
const _hoisted_11 = { class: "renaud_menu_popover" }
const _hoisted_12 = { class: "rounded-full p-2 bg-bg-2 hover:bg-bg-3 w-fit" }
const _hoisted_13 = { class: "w-screen bg-bg-0 h-14 fixed lg:hidden bottom-0 left-0 z-1001 flex items-center" }
const _hoisted_14 = { class: "m-auto w-full md:w-4/5 flex lg:hidden items-center justify-between" }
const _hoisted_15 = { style: {"position":"absolute","background":"#111827","top":"20%","left":"50%","transform":"translate(-50%)","padding":"20px","border-radius":"10px"} }
const _hoisted_16 = { style: {"width":"100%","text-align":"right","margin-bottom":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NavigationLinkPrimary = _resolveComponent("NavigationLinkPrimary")!
  const _component_question_icon = _resolveComponent("question-icon")!
  const _component_profile_icon = _resolveComponent("profile-icon")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BackToTop = _resolveComponent("BackToTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("nav", _hoisted_3, [
          _createVNode(_component_router_link, { to: {name :'home'} }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "rounded-xl h-10 w-10 mr-6"
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationLinks, (link, i) => {
                return (_openBlock(), _createBlock(_component_NavigationLinkPrimary, {
                  key: i,
                  to: link.name,
                  query: link.query,
                  params: link.params,
                  onClick: _ctx.openMenu
                }, null, 8, ["to", "query", "params", "onClick"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.menuHelp =true), ["prevent"])),
                  style: {"display":"inline-block"}
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_question_icon, { class: "w-auto h-6 stroke-text-alt fill-text-alt" })
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  (_ctx.menuHelp)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("ul", _hoisted_11, [
                          _createElementVNode("li", null, [
                            _createVNode(_component_router_link, {
                              to: {query : { modal : 'feedback'}},
                              title: "give a feedback"
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("Give a feedback")
                              ])),
                              _: 1
                            })
                          ]),
                          _cache[3] || (_cache[3] = _createElementVNode("li", null, [
                            _createElementVNode("a", {
                              href: "https://opsealog.zendesk.com/hc/en-150/requests/new",
                              target: "_blank",
                              title: "Contact support"
                            }, "Contact support")
                          ], -1)),
                          _cache[4] || (_cache[4] = _createElementVNode("li", null, [
                            _createElementVNode("a", {
                              href: "https://opsealog.zendesk.com/hc/en-150",
                              target: "_blank",
                              title: "Help center"
                            }, "Help center")
                          ], -1))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(_component_router_link, { to: {name : 'profile'} }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_profile_icon, { class: "w-auto h-6 stroke-text-alt fill-text-alt" })
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _createElementVNode("nav", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationLinks, (link, i) => {
            return (_openBlock(), _createBlock(_component_NavigationLinkPrimary, {
              key: i,
              to: link.name,
              query: link.query,
              params: link.params,
              onClick: _ctx.openMenu
            }, null, 8, ["to", "query", "params", "onClick"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(
        _ctx.$route.name !== 'map'
          ? 'grow px-5 lg:px-14 max-w-[1800px] mx-auto w-full'
          : 'grow w-full'
      )
      }, [
        _createVNode(_component_router_view)
      ], 2)
    ]),
    _createVNode(_component_BackToTop),
    (_ctx.displayFeedBackModal)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#end-of-doc"
        }, [
          _createVNode(_component_router_link, {
            to: {query : undefined},
            style: {"position":"fixed","background":"rgba(0,0,0,.3)","inset":"0"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_router_link, {
                    to: {query: undefined},
                    title: "Close"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("span", { class: "fa-stack" }, [
                        _createElementVNode("i", { class: "fa-solid text-slate-600 fa-circle fa-circle fa-stack-2x" }),
                        _createElementVNode("i", {
                          class: "fa-regular fa-circle-xmark fa-stack-1x dark:text-white",
                          style: {"font-size":"1.2em"}
                        })
                      ], -1)
                    ])),
                    _: 1
                  })
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("iframe", {
                  style: {"width":"600px","height":"400px","overflow-y":"auto"},
                  src: "https://forms.office.com/Pages/ResponsePage.aspx?id=ltYfK-hYCUmolLo2SwXjBOOnIHyFb-BJtK0zxdRPbGhUNlBNMkk3TzZXWFJWQjhYSzBGVDhOQ0pQQy4u"
                }, null, -1))
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}