export interface IHelper{
  clone: <T>(obj:T)=> T
}

export const Helper:IHelper = {
  clone: function <T> (obj:T): T {
    const _isDateString = (str:string) => {
      const dateRegex = /^((?:(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(?:\.\d+)?))(Z|[+-]\d{2}:\d{2})?)$/gm
      return dateRegex.test(str)
    }

    const _parseObjectAndCheckDates = (obj:T):T => {
      if (typeof obj !== 'object' || obj === null) {
        return obj // Base case: Not an object or null
      }

      const result:any = Array.isArray(obj) ? [] : {} // Create an array for arrays or an object for objects
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const value = obj[key]
          if (typeof value === 'string' && _isDateString(value)) {
            result[key] = new Date(value) // Convert valid date string to Date object
          } else if (typeof value === 'object') {
            // @ts-ignore
            result[key] = _parseObjectAndCheckDates(value) // Recursively parse nested objects or arrays
          } else {
            result[key] = value // Keep other data types unchanged
          }
        }
      }
      return result
    }

    const parsedObj = JSON.parse(JSON.stringify(obj))

    return _parseObjectAndCheckDates(parsedObj)
  }
}
