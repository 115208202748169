import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "headerScroll",
  style: {"overflow-x":"auto","height":"50px"},
  class: "my-custom-scrollbar"
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["data-observable", "data-month-key", "data-year-key"]
const _hoisted_4 = { class: "text-no-wrap text-uppercase" }
const _hoisted_5 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _directive_observer = _resolveDirective("observer")!

  return (_openBlock(), _createBlock(_component_v_col, { cols: "10" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "d-flex flex-column justify-center h-100",
          style: _normalizeStyle({ width : _ctx.timelineHelper.wrapperWidth +'px'})
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.timelineHelper.html.generateTH(), (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                "data-observable": item.attributeMonth === 'false' ? 'false': 'true',
                "data-month-key": item.attributeMonth,
                "data-year-key": item.attributeYear,
                style: _normalizeStyle([
                 {width : item.width+'px'},
                 {borderLeft : item.isBorderLeft ? 'white dotted 1px': '' }
               ])
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.title), 1)
              ], 12, _hoisted_3))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.timelineHelper.html.generateCol(), (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                style: _normalizeStyle([{"height":"0"}, {width : item.width+'px'}])
              }, null, 4))
            }), 128))
          ])
        ], 4)
      ])), [
        [_directive_observer, {router: _ctx.$router, route : _ctx.$route}]
      ])
    ]),
    _: 1
  }))
}