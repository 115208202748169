
import { defineComponent, inject, PropType } from 'vue'
import { IAssetWithPeriodManager } from '@/core/managers/entriesForAsset/entriesForAssetManager'
import { AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'

export default defineComponent({
  name: 'TimeLineContentWrapper',
  emits: ['update:assetWithPeriod'],
  setup () {
    const controller = new AbortController()
    const assetWithPeriodManager = inject('assetWithPeriodManager') as IAssetWithPeriodManager
    return { assetWithPeriodManager, controller }
  },
  props: {
    assets: {
      type: Array as PropType<AssetUIModel[]>,
      required: true
    }
  },
  data () {
    return { loading: false }
  },
  watch: {
    '$route.params.contractId': {
      immediate: true,
      handler: async function (value: string) {
        if (value && value !== '-1') {
          this.loading = true
          const _t = await this.assetWithPeriodManager.fetch(this.assets, value, this.controller.signal)
          this.$emit('update:assetWithPeriod', _t)
          this.loading = false
        }
      }
    }
  }
})
