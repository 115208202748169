
import { defineComponent } from 'vue'
import CardViewIcon from '@/components/icons/CardViewIcon.vue'
import ListIcon from '@/components/icons/ListIcon.vue'

export default defineComponent({
  components: { ListIcon, CardViewIcon },
  name: 'ListCardView',
  data () {
    return {
      type:
        sessionStorage.getItem('cardView') === 'true' ||
        !sessionStorage.getItem('cardView')
          ? 'card'
          : 'list',
      components: [
        { name: CardViewIcon, button: 'card' },
        { name: ListIcon, button: 'list' }
      ]
    }
  },
  methods: {
    changeView (type: string) {
      this.$emit('change-view', type)
      this.type = type
    }
  }
})
