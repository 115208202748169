
import { defineComponent, PropType } from 'vue'

import { AssetUIModel } from '@/core/managers/planningFilter/planningFilterModel'

export default defineComponent({
  name: 'timeLineRowRight',
  props: {
    asset: {
      type: Object as PropType<AssetUIModel>,
      required: true
    },
    contractId: {
      type: String,
      required: true
    }
  }
})
