import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "py-3 lg:py-6 h-100 relative"
}
const _hoisted_2 = { class: "flex flex-wrap gap-4" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row relative mt-5" }
const _hoisted_4 = { class: "grow mr-6 w-full" }
const _hoisted_5 = {
  key: 1,
  class: "grid grid-cols-1 lg:grid-cols-6 mt-6 gap-4"
}

import * as CategoryManager from '@/core/managers/category.manager'
import { ServiceDetailsModel } from '@/core/model/service.model'
import DashboardCard from '@/components/cards/DashboardCard.vue'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import serviceLoading from '@/components/analytics/services/details-page.loading.vue'
import serviceTitle from '@/components/analytics/services/title.vue'
import subPart from '@/components/analytics/services/sub-part.vue'
import reportSection from '@/components/analytics/services/report-section.vue'
import kpi from '@/components/analytics/kpi/kpi.vue'
import chart from '@/components/analytics/charts/chart.vue'
import Filter from '@/components/analytics/filters/filter-select.vue'
import { ref, onMounted } from 'vue'
import HScroller from '@/components/common/horizontal-arrow-scoller.vue'
import { FilterFormatted } from '@/core/model/filter.model'


export default /*@__PURE__*/_defineComponent({
  __name: 'Analytics.detail',
  props: {
    analyticsId: {},
    filter: {}
  },
  setup(__props: any) {

const props = __props
const detail = ref<ServiceDetailsModel>({} as ServiceDetailsModel)
const filtersToApply = ref<FilterFormatted[]>([])
const isLoading = ref<boolean>(true)

onMounted(async () => {
  const response = await CategoryManager.Manager.detail(parseInt(props.analyticsId))
  detail.value = response.data as ServiceDetailsModel

  let appliedFilters: FilterFormatted[] = []
  if (props.filter !== undefined) {
    appliedFilters = JSON.parse(decodeURI(props.filter)) as FilterFormatted[]
  }

  filtersToApply.value = detail.value.filters.map(f => {
    let members: string|number|string[]|number[]|undefined
    const defaultFilter = appliedFilters.find(af => af.id === f.id)
    if (defaultFilter !== undefined) {
      members = defaultFilter.members
    }
    return {
      id: f.id,
      isLoading: true,
      members: members
    } as FilterFormatted
  })

  isLoading.value = false
})


return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createBlock(serviceLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(serviceTitle, {
          title: detail.value.title,
          link: 'analytics'
        }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detail.value.filters, (f, i) => {
            return (_openBlock(), _createBlock(Filter, {
              filter: f,
              filtersApplied: filtersToApply.value,
              key: f.id,
              "is-loading": filtersToApply.value[i].isLoading,
              "onUpdate:isLoading": ($event: any) => ((filtersToApply.value[i].isLoading) = $event),
              "filter-value": filtersToApply.value[i].members,
              "onUpdate:filterValue": ($event: any) => ((filtersToApply.value[i].members) = $event)
            }, null, 8, ["filter", "filtersApplied", "is-loading", "onUpdate:isLoading", "filter-value", "onUpdate:filterValue"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (detail.value.kpis)
              ? (_openBlock(), _createBlock(HScroller, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detail.value.kpis, (k, i) => {
                      return (_openBlock(), _createBlock(kpi, {
                        kpi: k,
                        "filters-applied": filtersToApply.value,
                        key: i
                      }, null, 8, ["kpi", "filters-applied"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (detail.value.charts)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detail.value.charts, (c, i) => {
                    return (_openBlock(), _createBlock(GenericCardShell, {
                      class: _normalizeClass(["h-[460px]", `grid-${c.size}`]),
                      key: i
                    }, {
                      default: _withCtx(() => [
                        _createVNode(chart, {
                          chart: c,
                          "filters-applied": filtersToApply.value
                        }, null, 8, ["chart", "filters-applied"])
                      ]),
                      _: 2
                    }, 1032, ["class"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (detail.value.dashboards && detail.value.dashboards.length)
              ? (_openBlock(), _createBlock(subPart, {
                  key: 2,
                  part: 'dashboard'
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detail.value.dashboards, (d, i) => {
                      return (_openBlock(), _createBlock(DashboardCard, {
                        id: d.id,
                        title: d.title,
                        description: d.description,
                        key: i
                      }, null, 8, ["id", "title", "description"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (detail.value.reports && detail.value.reports.length > 0)
              ? (_openBlock(), _createBlock(reportSection, {
                  key: 3,
                  analyticsId: parseInt(_ctx.analyticsId),
                  reports: detail.value.reports,
                  "sub-title": detail.value.title
                }, null, 8, ["analyticsId", "reports", "sub-title"]))
              : _createCommentVNode("", true),
            _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "mt-20 mb-10 border-t-gray-800" }, null, -1))
          ])
        ])
      ]))
}
}

})