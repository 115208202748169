import { ChartModel } from '@/core/model/chart.model'
import { FilterFormatted, getValidFilters } from '@/core/model/filter.model'
import { ApiResult, http } from '@/core/services/request.service'
import router from '@/router'

export const Manager = {
  detail (id: number, filters?: FilterFormatted[], signal?: AbortSignal): Promise<ApiResult<ChartModel>> {
    const body = {
      filters: getValidFilters(filters ?? [])
    }

    return http.request<ChartModel>('post', `Charts/${id}`, body, { signal: signal })
      .then((rep: ApiResult<ChartModel>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }
}
