import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-100 d-flex justify-center align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert_title = _resolveComponent("v-alert-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_ctx.loading || _ctx.error !== '' )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.error !== '')
          ? (_openBlock(), _createBlock(_component_v_alert, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_alert_title, null, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("error")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_v_progress_circular, {
              key: 1,
              indeterminate: "",
              "model-value": "20"
            }))
      ]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}