import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "h-full w-full flex flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "w-full"
}
const _hoisted_4 = {
  class: "body body-2 body-alt block",
  style: {"font-family":"'arial'"}
}
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "body body-1" }
const _hoisted_8 = { class: "!text-text-alt" }

import { computed, ref, toRef } from 'vue'
import { KpiInfo, KpiModel } from '@/core/model/kpi.model'
import { ChartInfo, ChartModel } from '@/core/model/chart.model'
import { getVisualComponent } from '@/components/analytics/charts/visuals/index'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import FormatService from '@/core/services/format.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'standard',
  props: {
    chartInfo: {},
    kpiInfo: {},
    chart: {},
    kpi: {}
  },
  setup(__props: any) {

const props = __props

const options: IGeneratorOption = {
  height: '87px',
  isDetailed: false,
  title: props.chartInfo.label,
  description: props.chartInfo.description,
  legends: {
    enable: false
  },
  margin: [0, 0, 0, 0]
}

const kpiClass = computed(() => {
  if (props.kpi && props.kpi.kpis && props.kpi.kpis.length > 1) {
    return 'w-2/5 flex flex-col justify-center gap-2'
  }
  return 'w-2/5'
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.kpi)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpi.kpis, (kpiValue, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", {
                      class: "truncate",
                      title: kpiValue.label || _ctx.kpiInfo.label
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_unref(FormatService).formatNumber(+kpiValue.value)) + " " + _toDisplayString(kpiValue.unit), 1)
                    ], 8, _hoisted_6)
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(kpiValue.label || _ctx.kpiInfo.label), 1)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.chart)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getVisualComponent)(_ctx.chartInfo.type)), {
            key: 1,
            values: _ctx.chart,
            options: options,
            class: "h-full boorder mx-[-1rem]"
          }, null, 8, ["values"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})