import { FilterFormatted, getValidFilters } from '@/core/model/filter.model'
import { KpiModel } from '@/core/model/kpi.model'
import { ApiResult, http } from '@/core/services/request.service'

export const Manager = {
  detail (id: number, filters?: FilterFormatted[], signal?: AbortSignal): Promise<ApiResult<KpiModel>> {
    const body = {
      filters: getValidFilters(filters ?? [])
    }

    return http.request<KpiModel>('post', `Kpis/${id}`, body, { signal: signal })
  }
}
