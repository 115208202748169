export const fleetCardProps = {
  cardView: { type: Boolean, default: true },
  id: { type: Number, required: true },
  name: String,
  type: { type: String, required: true },
  country: { type: String, required: true },
  course: Number,
  lastCourseUpdate: String,
  speed: String,
  length: String,
  imo: String,
  mmsi: String,
  isFav: Boolean,
  categoryId: { type: Number, required: true },
  loadingCard: Boolean,
  imgUrl: String
}
