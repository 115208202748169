
import { defineComponent } from 'vue'
import { AssetAndContractModel, AssetUIModel, ContractModel } from '@/core/managers/planningFilter/planningFilterModel'
import timelinePopover from '@/views/feat/timelinePopover.vue'
import TimelineActionBar from '@/components/TimelineActionBar.vue'
import TimeLineContentWrapper from '@/components/TimeLineContentWrapper.vue'
import TimeLinePageWrapper from '@/components/TimeLinePageWrapper.vue'
import TimeLineHeaderScroll from '@/components/TimeLineHeaderScroll.vue'
import TimeLineContent from '@/components/TimeLineContent.vue'

export default defineComponent({
  name: 'timelinePage',
  components: {
    TimeLineHeaderScroll,
    TimeLinePageWrapper,
    TimelineActionBar,
    timelinePopover,
    TimeLineContentWrapper,
    TimeLineContent
  },
  watch: {
    /* '$route.params.contractId': {

      handler: async function (value: string) {
        this.crtlFetchPeriod.abort()
        if (value) {
          await this.assetByContractComposable.fetch(value, this.crtlFetchPeriod.signal)
        }
      }
    }, */

  },

  data () {
    return { filterText: '', position: [] as Record<string, string>[], assetId: '-1', periodId: '-1', toggle: false, contracts: [] as ContractModel[], assetWithoutPeriodList: [] as AssetUIModel[], assetWithPeriodList: [] as AssetUIModel[] }
  },
  mounted () {
    const eventDelegate = document.getElementById('eventDelegate') as HTMLElement
    const popoverMaxHeight = 300
    eventDelegate.addEventListener('click', (e:MouseEvent) => {
      const dataset = (e.target as HTMLElement).dataset
      if (dataset.popover && dataset.popover === 'true') {
        this.assetId = dataset.assetId as string
        this.periodId = dataset.periodId as string
        this.toggle = true
        this.position.push({ left: e.clientX + 'px' })
        this.position.push({ top: e.clientY + 'px' })
        this.position.push({ transform: `translateY(${document.body.clientHeight < e.clientY + popoverMaxHeight ? -250 : 0}px)` })
      }
      if (dataset.redirect && dataset.redirect === 'true') {
        this.$router.push({ name: 'period', params: { contractId: this.$route.params.contractId, assetId: dataset.assetId, rangeTimeId: this.$route.params.rangeTimeId } })
      }
    })
  },
  methods: {
    onAssetAndContract (assetAndContract : AssetAndContractModel) {
      this.assetWithoutPeriodList = assetAndContract.assets
      this.contracts = assetAndContract.contracts
    },
    onAssetWithPeriod (assets : AssetUIModel[]) {
      this.assetWithPeriodList = assets
    },
    filterByContract (items : AssetUIModel[], contractId : number): AssetUIModel[] {
      return items.filter(x => x.contractId === contractId)
    }
  }

})
