export interface FilterMember {
    value: string;
    label: string;
    members: FilterMember[];
}

export interface FilterModel {
    defaultSelected: string;
    members: FilterMember[]
}

export interface Filter {
    id: number
    label: string
}

export interface FilterFormatted {
    id: number,
    isLoading: boolean,
    members: string | number | string[] | number[]
}

export function getValidFilters (filters: FilterFormatted[]) {
  return filters.filter(f => {
    if (Array.isArray(f.members) || typeof f.members === 'string') {
      return f.members.length > 0
    }
    if (typeof f.members === 'number') {
      return f.members >= 0
    }
    return false
  })
}
