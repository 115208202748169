import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import GenericCardShell from '@/components/cards/GenericCardShell.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'card.loading',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GenericCardShell, {
    class: "min-h-24 flex relative h-[102px] p-4 w-fit min-w-[14rem] min-w-[13rem]",
    style: {"min-width":"14rem"}
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "flex mr-2 w-5 flex-col h-7 justify-center" }, [
        _createElementVNode("div", { class: "w-3 h-3 rounded-full skeleton-dot" })
      ], -1),
      _createElementVNode("div", { class: "w-5/6" }, [
        _createElementVNode("div", { class: "body body-2 text-ellipsis relative table-cell align-middle h-7 skeleton w-4/5" }),
        _createElementVNode("h4", { class: "my-1.5 skeleton w-4/5" })
      ], -1)
    ])),
    _: 1
  }))
}
}

})