
import { defineComponent } from 'vue'
import { timeFormat } from 'd3'

export default defineComponent({
  name: 'TimelineMonthNavBtn',
  emits: [''],
  components: {},
  setup () {
    return {}
  },
  data () {
    return {}
  },
  props: {},
  computed: {},
  methods: {
    onIncrementMonth (value: number) {
      const oldYear = (this.$route.params.rangeTimeId as string).split('-')[0]
      const oldMonth = (this.$route.params.rangeTimeId as string).split('-')[1]
      const newDate = new Date(parseInt(oldYear), (parseInt(oldMonth) - 1) + value)
      const formatA = timeFormat('%Y-%m')
      this.$router.push({ params: { rangeTimeId: formatA(newDate) }, query: { scroll: 'duration' } })
    }
  }
})
