import { ApiContractFilter } from '@/core/managers/planningFilter/planningFilterApi'
import { AssetAndContractModel, AssetUIModel, ContractModel } from '@/core/managers/planningFilter/planningFilterModel'

export interface IPlanningFilterMapper {
  fetchDecoupling: (inputList : ApiContractFilter[])=> AssetAndContractModel
}

export const CreatePlanningFilterMapper = function (): IPlanningFilterMapper {
  return {
    fetchDecoupling: function (inputList:ApiContractFilter[]):AssetAndContractModel {
      return {
        contracts: inputList.reduce((list:ContractModel[], row) => {
          const temp = list.find(x => x.id === row.contract_id)
          if (!temp) {
            list.push({
              id: row.contract_id,
              name: row.contract
            })
          }
          return list
        }, []),
        assets: inputList.reduce((list:AssetUIModel[], row) => {
          row.assets.forEach(item => {
            list.push({
              id: item.id,
              name: item.name,
              contractId: row.contract_id,
              contractName: row.contract,
              status: item.status,
              status_color: item.status_color,
              image_url: item.image_url || require('@/assets/img/vessel_placeholder.png'),
              entries: []
            })
          })
          // todo remove limit
          return list
          //  return list.slice(0, 1)
        }, [])
      }
    }
  }
}
