
import { defineComponent, PropType } from 'vue'
import { IdNameModel, Report2Manager, ReportSubscriptionRowModel } from '@/map/core/bll/report/reportManager'

export default defineComponent({
  props: {
    attribute: {
      type: Object as PropType<ReportSubscriptionRowModel>,
      required: true
    },
    labels: {
      type: Array as PropType<IdNameModel[]>,
      required: true
    }
  },
  methods: {
    getLabel (labels: IdNameModel[], id : number) {
      return labels.find(item => item.id === id) || { id: -1, name: 'not found' }
    },
    onChange (item: ReportSubscriptionRowModel) {
      item.pending = true
      const reportId = parseInt(this.$route.query.id as string)
      const params = `${reportId}/${item.attributeId}`
      Report2Manager.updateSubscription(params, { checked: !item.checked }).then(() => {
        item.checked = !item.checked
        item.pending = false
      })
    }
  }
})
