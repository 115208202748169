
import { defineComponent, inject } from 'vue'
import { IAssetAndContractManager } from '@/core/managers/planningFilter/planningFilterManager'

export default defineComponent({
  name: 'TimeLinePageWrapper',
  emits: ['update:assetAndContract'],
  components: {},
  setup () {
    const controller = new AbortController()
    const assetAndContractManager = inject('planningFilterManager') as IAssetAndContractManager
    return { controller, assetAndContractManager }
  },
  data () {
    return { loading: false, error: '' }
  },
  async created () {
    this.loading = true
    const _t = await this.assetAndContractManager.fetch(this.controller.signal)

    // todo improve error management
    if (_t.contracts.length === 0) {
      this.error = 'error, contract list is empty'
    }
    if (_t.assets.length === 0) {
      this.error = 'error, assets list is empty'
    }

    if (_t.contracts.length > 0 && this.$route.params.contractId === '-1') {
      await this.$router.push({ params: { contractId: _t.contracts[0].id } })
    }

    this.loading = false
    this.$emit('update:assetAndContract', _t)
  },
  beforeUnmount () {
    this.controller.abort()
  }
})
