import store from '@/store'

export const textGray50 = '#f9fafb'
export const textGray100 = '#f3f4f6'
export const textGray200 = '#e5e7eb'
export const textGray300 = '#d1d5db'
export const textGray400 = '#9ca3af'
export const textGray500 = '#6b7280'
export const textGray600 = '#4b5563'
export const textGray700 = '#374151'
export const textGray800 = '#1f2937'
export const textGray900 = '#111827'
export const textWhite = '#fff'
export const textPrim = '#e3e8f3'
export const textAlt = '#a9b8db'
export const bg0 = '#0c111c'
export const bg2 = '#192339'
export const semanticActivate = '#60A5FA'

export default class ColoursService {
  public static randomColour (): string {
    return '#' + (Math.random().toString(16) + '00000').slice(2, 8)
  }

  public static stringToColour (str: string): string {
    if (!str) {
      return this.randomColour()
    }
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF
      colour += ('00' + value.toString(16)).substr(-2)
    }
    return colour
  }

  public static getColour (title: string): string {
    if (store.state.labels && title) {
      const labelIndex = store.state.labels.findIndex(label => label.text.toLowerCase() === title.toLowerCase())
      if (labelIndex > -1) {
        return store.state.labels[labelIndex].color
      }
    }
    return this.stringToColour(title)
  }

  public static colourLightness (colour: string, decimal: number): string {
    const base = colour.startsWith('#') ? 1 : 0

    let r = parseInt(colour.substring(base, 3), 16)
    let g = parseInt(colour.substring(base + 2, 5), 16)
    let b = parseInt(colour.substring(base + 4, 7), 16)

    r = Math.round(r / decimal)
    g = Math.round(g / decimal)
    b = Math.round(b / decimal)

    r = (r < 255) ? r : 255
    g = (g < 255) ? g : 255
    b = (b < 255) ? b : 255

    const rr = ((r.toString(16).length === 1) ? `0${r.toString(16)}` : r.toString(16))
    const gg = ((g.toString(16).length === 1) ? `0${g.toString(16)}` : g.toString(16))
    const bb = ((b.toString(16).length === 1) ? `0${b.toString(16)}` : b.toString(16))
    return `#${rr}${gg}${bb}`
  }
}
