
import { defineComponent, inject } from 'vue'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'

export default defineComponent({
  name: 'TimelineMonthNavSelect',
  emits: [''],
  components: {},
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  },
  data () {
    return {}
  },
  props: {},
  computed: {},
  methods: {
    setYear (selectedYear: string, rangeTimeId :string, months : {id: string, name : string}[]) {
      const _currentMonth = rangeTimeId.split('-')[1]
      const _t = months.find(x => x.id === `${selectedYear}-${_currentMonth}`)
      if (_t) {
        this.$router.push({ params: { rangeTimeId: `${selectedYear}-${_currentMonth}` }, query: { scroll: 'duration' } })
      } else {
        const _m = months.filter(x => x.id.indexOf(selectedYear) > -1)
        this.$router.push({ params: { rangeTimeId: _m[0].id }, query: { scroll: 'duration' } })
      }
    },
    setMonth (rangeTimeId :string, value :string) {
      this.$router.push({ params: { rangeTimeId: value }, query: { scroll: 'duration' } })
    },
    getYear (rangeTimeId :string | null):string {
      return rangeTimeId ? rangeTimeId.split('-')[0] : '-1'
    },
    getMonth (rangeTimeId :string | null):string {
      return rangeTimeId || '-1'
    },
    filterMonth (rangeTimeId :string, months : {id: string, name : string}[]) {
      return months.filter(x => x.id.indexOf((rangeTimeId as string).split('-')[0]) > -1)
    }
  }
})
