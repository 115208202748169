
import { defineComponent, PropType } from 'vue'
import TimelineMonthNavBtn from '@/components/TimelineMonthNavBtn.vue'
import TimelineMonthNavSelect from '@/components/TimelineMonthNavSelect.vue'
import { ContractModel } from '@/core/managers/planningFilter/planningFilterModel'

export default defineComponent({
  name: 'TimelineActionBar',
  components: {
    TimelineMonthNavBtn,
    TimelineMonthNavSelect
  },
  props: {
    contracts: {
      type: Array as PropType<ContractModel[]>,
      required: true
    }
  },
  methods: {
    contractChange (contractId: string) {
      this.$router.push({ params: { contractId } })
    }
  }
})
