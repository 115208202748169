import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, {
    class: "bg-surface periodElement d-flex align-center",
    to: {name : 'period', params: { contractId: _ctx.contractId, assetId: _ctx.asset.id, rangeTimeId : this.$route.params.rangeTimeId}}
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_v_badge, {
        color: _ctx.asset.status_color,
        dot: "",
        "offset-y": "-5",
        "offset-x": "5"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_img, {
            width: 50,
            "max-height": "40",
            rounded: "",
            gradient: "180deg, rgba(0,0,0,0) 80%, rgba(0,0,0,1) 100%",
            class: "me-2",
            src: _ctx.asset.image_url
          }, null, 8, ["src"])
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list_item_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.asset.name), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}