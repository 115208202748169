import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_timeline_month_nav_select = _resolveComponent("timeline-month-nav-select")!
  const _component_timeline_month_nav_btn = _resolveComponent("timeline-month-nav-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "my-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_tabs, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tab, { value: 1 }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("On/Off Hire")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_select, {
                  width: "200",
                  items: _ctx.contracts,
                  "hide-details": true,
                  "hide-no-data": true,
                  "item-value": "id",
                  "model-value": parseInt(_ctx.$route.params.contractId),
                  "onUpdate:modelValue": _ctx.contractChange,
                  class: "me-1"
                }, null, 8, ["items", "model-value", "onUpdate:modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_timeline_month_nav_btn, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_timeline_month_nav_select)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}