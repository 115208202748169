
import Close from '@/components/icons/Close.vue'
import HelpCenter from '@/components/icons/HelpCenter.vue'
import HomeIcon from '@/components/icons/HomeIcon.vue'
import Services from '@/components/icons/Services.vue'
import { computed, defineComponent } from 'vue'
import ExpandIcon from '@/components/icons/ExpandIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'

export default defineComponent({
  name: 'TopButton.vue',
  components: {
    HelpCenter,
    Services,
    HomeIcon,
    Close,
    ExpandIcon,
    LogoutIcon

  },
  props: {
    type: String,
    title: String,
    primary: { type: Boolean, default: false },
    routeName: String
  },
  setup (props, context) {
    const specificClass = computed(() => {
      if (props.primary) {
        if (props.type === 'logout') {
          return 'text-semantic-negative bg-gray-50 stroke-gray-900'
        }
        if (props.type === 'expand') {
          return 'text-bg-0 bg-semantic-activate stroke-gray-900 border-semantic-activate'
        }
        return 'text-gray-900 bg-gray-50 stroke-gray-900'
      }
      return 'mx-4 text-gray-50 hover:bg-gray-600 stroke-gray-50'
    })
    const svgClass = computed(() => {
      if (!props.title) {
        return 'h-6 w-6 inline-block align-text-top'
      }
      return 'h-6 w-6 mr-2.5 inline-block align-text-top'
    })
    const icon = computed(() => {
      switch (props.type) {
        case 'home':
          return 'HomeIcon'
        case 'services':
          return 'Services'
        case 'help':
          return 'HelpCenter'
        case 'close':
          return 'Close'
        case 'expand':
          return 'ExpandIcon'
        case 'logout':
          return 'LogoutIcon'
        default:
          return ''
      }
    })
    const handleClick = () => {
      if (!props.routeName) {
        context.emit('close')
      }
    }
    return { svgClass, icon, specificClass, handleClick }
  }
})
