
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CardViewIcon',
  props: {
    stroke: { type: String, default: 'currentColor' },
    fill: { type: String, default: 'currentColor' }
  }
})
