import colors from 'vuetify/util/colors'

export const myCustomDarkTheme = {
    dark: true,
    colors: {
        error: colors.red.darken1,
        primary: '#60A5FA',
        'on-primary' : '#192339',
        secondary: '#fdbe00',
        'surface': '#192339',// v-card
        'surface-light': '#111827',// v-toolbar // text-field-background  //v-expand
        'surface-bright': 'green',
        'on-surface-light': '#bac5d0'
    },
    variables: {
        'disabled-opacity': 0.6,
        /*  'btn-size': '1rem',
          'icon-size-multiplier': 0.6*/
    }
}
