import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "font-display my-2 flex items-center" }

import { CategoryModel } from '@/core/model/category.model'
import ServicePreview from '@/components/analytics/services/previews/preview.vue'
import HScroller from '@/components/common/horizontal-arrow-scoller.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'category-list-item',
  props: {
    category: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.category.name), 1),
      _createVNode(HScroller, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.services, (item, i) => {
            return (_openBlock(), _createBlock(ServicePreview, {
              service: item,
              key: i,
              class: "w-[310px] min-w-[310px]"
            }, null, 8, ["service"]))
          }), 128))
        ]),
        _: 1
      }),
      _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "my-4 border-transparent" }, null, -1))
    ])
  ]))
}
}

})