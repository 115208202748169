import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-center align-center",
  style: {"height":"350px"}
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, {
          indeterminate: "",
          "model-value": "20"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}