
import { ref } from 'vue'

const position = ref(0)
let direction = 'left'

let isIntervalActive = true
let intervalId: number | null = null
let _sliderLength = 0

const _checkLimit = function () {
  if (position.value > _sliderLength - 2) {
    position.value = 0
  } else {
    position.value++
  }
}

const updateDirection = function (newVal : number, oldVal : number):void {
  direction = newVal > oldVal ? 'left' : 'right'
}

const initEvent = function (sliderLength : number):void {
  _sliderLength = sliderLength
  isIntervalActive = true
  intervalId = null
  position.value = 0
  nextEvent()
}

const stopEvent = function (): void {
  if (intervalId) {
    window.clearInterval(intervalId)
    intervalId = null
  }
}

const nextEvent = function (): void {
  if (!intervalId && isIntervalActive) {
    intervalId = window.setInterval(_checkLimit, 6000)
  }
}

const removeEvent = function (): void {
  stopEvent()
  position.value = 0
}

const changePosition = function (value : number): void {
  isIntervalActive = false
  position.value = value
}

export default {
  position,
  direction,
  updateDirection,
  stopEvent,
  removeEvent,
  nextEvent,
  initEvent,
  changePosition
}
