import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex items-center flex-col lg:flex-row" }
const _hoisted_3 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.to !== 'operations' || (_ctx.to === 'operations' && _ctx.isInternalUser)  )
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: _ctx.to, query: _ctx.query, params : _ctx.params },
          onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideBar = true)),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideBar = false)),
          class: _normalizeClass([[ _ctx.stroke.includes(_ctx.to)
                  ? 'stroke-text-alt fill-text-alt hover:stroke-text-alt'
                  : 'fill-text-alt hover:fill-text-alt',
                _ctx.activeLink && !_ctx.fill.includes(_ctx.to)
                  ? 'text-bg-0 stroke-bg-0 fill-bg-0 !font-bold bg-semantic-activate hover:text-text-alt hover:stroke-text-alt hover:fill-text-alt'
                  : _ctx.activeLink && _ctx.fill.includes(_ctx.to)
                    ? 'text-bg-0 fill-bg-0 !font-bold bg-semantic-activate hover:text-text-alt hover:fill-text-alt'
                    : 'text-text-alt',
      ], "no-underline block lg:inline-block hover:bg-bg-2 rounded-md p-0.5 xxs:p-2 mx-4 sm:mx-3 font-normal w-full lg:w-auto"])
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-3 h-3 xxs:w-5 xxs:h-5 mr:0 lg:mr-1 mb-1 lg:mb-0" })),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.to==='operations')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("Contract management")
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.to}.title`)), 1)
                    ], 64))
              ])
            ])
          ]),
          _: 1
        }, 8, ["to", "class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        {
          'absolute -bottom-3.5 h-0.75 w-full bg-semantic-activate rounded-lg hidden lg:block p-0':
            _ctx.activeLink,
        },
        { '!hidden': _ctx.hideBar },
      ])
    }, null, 2)
  ]))
}