import * as FleetManager from '@/core/managers/fleet.manager'
import { AxiosResponse } from 'axios'
import { getCurrentInstance, Ref, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default function manageFavourite (
  id: number,
  categoryId: number,
  loading: boolean,
  isFavourite: boolean
)
  : {
    loadingRef: Ref<boolean>,
    isFavouriteRef: Ref<boolean>,
    changeFavStatus: () => void,
    emitFavourite: () => void
  } {
  const loadingRef = ref(loading)
  const isFavouriteRef = ref(isFavourite)
  const { emit } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>
  const toast = useToast()

  function changeFavStatus () {
    loadingRef.value = true
    FleetManager.Manager.manageFavourite(categoryId, id, !isFavouriteRef.value).then(
      (response: AxiosResponse) => {
        if (response.status === 200) {
          loadingRef.value = false
          isFavouriteRef.value = !isFavouriteRef.value
        } else {
          toast.error('An error occured while managing this favourite')
          loadingRef.value = false
        }
      }
    )
  }

  function emitFavourite () {
    emit('changeFavStatus', isFavourite)
  }

  return {
    loadingRef,
    isFavouriteRef,
    changeFavStatus,
    emitFavourite
  }
}
