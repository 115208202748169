
import PageTitle from '@/components/titles/PageTitle.vue'
import { SectorModel } from '@/core/model/sector.model'
import { defineComponent } from 'vue'
import CardButton from '@/components/buttons/CardButton.vue'

export default defineComponent({
  components: {
    PageTitle,
    CardButton
  },
  data () {
    return {
      imgService: ` url(${require('@/assets/img/home-photo-services.png')}`,
      imgMap: ` url(${require('@/assets/img/home-photo-map.png')}`,
      imgHelp: ` url(${require('@/assets/img/home-photo-help.png')}`,
      list: [] as SectorModel[],
      pages: [
        {
          title: 'Analytics',
          subtitle: "Explore your fleet's key indicators.",
          pageName: 'analytics',
          poster: require('@/assets/homepage/ANALYTICS.jpg'),
          video: require('@/assets/homepage/ANALYTICS.mp4'),
          hover: false,
          sameApp: true
        },
        {
          title: 'Map',
          subtitle:
            'Your interactive tool for real-time vessel tracking from global overview to the single vessel.',
          pageName: 'map',
          poster: require('@/assets/homepage/MAP.jpg'),
          video: require('@/assets/homepage/MAP.mp4'),
          hover: false,
          sameApp: true,
          query: { flyToBounds: 'true' }
        },
        {
          title: 'Fleet',
          subtitle:
            'Single vessel focus for operations monitoring, vessel tracking and key operational indicators.',
          pageName: 'fleet',
          poster: require('@/assets/homepage/FLEET.jpg'),
          video: require('@/assets/homepage/FLEET.mp4'),
          hover: false,
          sameApp: true
        },
        {
          title: 'Reports',
          subtitle: 'Consult all your reports available in this library.',
          pageName: 'allReports',
          poster: require('@/assets/homepage/REPORTS.jpg'),
          video: require('@/assets/homepage/REPORTS.mp4'),
          hover: false,
          sameApp: true
        },
        {
          title: 'Dashboards',
          subtitle: 'Explore and compare your data in your Dashboard catalog.',
          pageName: 'allDashboards',
          poster: require('@/assets/homepage/DASHBOARD.jpg'),
          video: require('@/assets/homepage/DASHBOARD.mp4'),
          hover: false,
          sameApp: true
        },
        {
          title: 'Contact us',
          subtitle: 'Our experts will revert shortly.',
          pageName: 'https://opsealog.zendesk.com/hc/en-150',
          poster: require('@/assets/homepage/NEED_A_QUESTION.jpg'),
          video: require('@/assets/homepage/NEED_A_QUESTION.mp4'),
          hover: false,
          sameApp: false
        }
      ]
    }
  }
})
