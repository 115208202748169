import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex p-1 rounded-md bg-bg-0 w-fit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, i) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(component.name), {
        key: i,
        onClick: ($event: any) => (_ctx.changeView(component.button)),
        class: _normalizeClass(["cursor-pointer h-6 w-6 p-1 rounded-md fill-text-alt m-auto hover:bg-bg-2", [
        _ctx.type === component.button
          ? 'bg-semantic-activate !fill-bg-0 hover:!fill-text-alt shadowed'
          : 'bg-transparent',
        i !== _ctx.components.length - 1 ? 'mr-1' : '',
      ]])
      }, null, 8, ["onClick", "class"]))
    }), 128))
  ]))
}