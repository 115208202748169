
import { defineComponent } from 'vue'

export default defineComponent({
  inject: ['GStore'],
  components: {},
  data () {
    return {}
  },
  computed: {},
  methods: {}
})
