
import { defineComponent, DirectiveBinding, inject } from 'vue'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { easePoly, interpolateNumber, select } from 'd3'

let timeoutId: ReturnType<typeof setTimeout>
export default defineComponent({
  name: 'TimeLineHeaderScroll',

  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  },
  watch: {
    '$route.params.rangeTimeId': {
      immediate: true,
      handler: function (value :string) {
        if (value && value !== '-1') {
          const _scrollByXOffset = (offset:number, duration: number) => {
            const scrollableX = document.getElementById('headerScroll')
            if (scrollableX) {
              const currentScrollLeft = scrollableX.scrollLeft
              const targetScrollLeft = offset
              select(scrollableX)
                .transition()
                .duration(duration)
                .ease(easePoly.exponent(3))
                .tween('scroll', () => {
                  return function (t:number) {
                    scrollableX.scrollLeft = interpolateNumber(currentScrollLeft, targetScrollLeft)(t)
                  }
                })
                .on('end', () => {
                  this.$router.push({ query: undefined })
                })
            }
          }

          this.$nextTick(() => {
            if (this.$route.query && this.$route.query.scroll && this.$route.query.scroll === 'duration') {
              const duration = this.$route.query.scroll ? 1500 : 0
              const offset = this.timelineHelper.scrollTo(this.$route.params.rangeTimeId as string)
              _scrollByXOffset(offset, duration)
            } else {
              console.log(value)
              const offset = this.timelineHelper.scrollTo(this.$route.params.rangeTimeId as string)
              _scrollByXOffset(offset, 0)
            }
          })
        }
      }
    }
  },
  directives: {
    observer: {
      mounted (element: HTMLElement, binding : DirectiveBinding) {
        const router = binding.value.router

        const callBack = function (entries: IntersectionObserverEntry[]) {
          const y: IntersectionObserverEntry[] = []
          entries.forEach(function (entry) {
            if (entry.intersectionRatio > 0) {
              y.push(entry)
            }
          })
          if (y.length > 0) {
            router.push({ params: { rangeTimeId: (y[0].target as HTMLElement).dataset.monthKey } })
          }
        }

        const observer = new IntersectionObserver(callBack, {
          root: element,
          rootMargin: '0px -30% 0px -20%'
        })

        const spies = document.querySelectorAll('[data-observable=true]')

        const t = function () {
          element.removeEventListener('scrollend', t)
          spies.forEach(function (spy) {
            observer.observe(spy)
          })
          setTimeout(function () {
            observer.disconnect()
          }, 1)
        }
        element.addEventListener('mouseup', async (event:Event) => {
          observer.disconnect()
        })
        element.addEventListener('mousedown', async (event:Event) => {
          element.addEventListener('scrollend', t)
        })
      }
    }

  }
})
