<template>
  <svg
    height="682.66669pt"
    viewBox="-21 -21 682.66669 682.66669"
    width="682.66669pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m345 502.5h-50v-50h50zm0-146.070312c48.339844-11.695313 81.832031-55.550782 79.921875-105.144532-2.074219-54.003906-46.382813-97.917968-100.867187-99.957031-29.109376-1.097656-56.601563 9.328125-77.382813 29.339844-20.425781 19.675781-31.671875 46.070312-31.671875 74.332031h50c0-14.550781 5.804688-28.160156 16.351562-38.316406 10.910157-10.507813 25.398438-15.957032 40.828126-15.394532 28.519531 1.074219 51.703124 23.875 52.78125 51.917969 1 26.132813-17.035157 49.222657-42.898438 54.898438-21.824219 4.789062-37.0625 23.648437-37.0625 45.863281v48.53125h50zm201.273438 189.84375c60.441406-60.4375 93.726562-140.800782 93.726562-226.273438s-33.285156-165.835938-93.726562-226.273438c-60.4375-60.441406-140.800782-93.726562-226.273438-93.726562s-165.835938 33.285156-226.273438 93.726562c-60.441406 60.4375-93.726562 140.800782-93.726562 226.273438s33.285156 165.835938 93.726562 226.273438c60.4375 60.441406 140.800782 93.726562 226.273438 93.726562s165.835938-33.285156 226.273438-93.726562zm43.726562-226.273438c0 148.875-121.125 270-270 270s-270-121.125-270-270 121.125-270 270-270 270 121.125 270 270zm0 0"
    />
  </svg>
</template>
