import { Map } from 'leaflet'
import { Router } from 'vue-router'
import { computed, watch } from 'vue'
import categoryStore from '@/map/core/bll/category/category.store'
import assetPopover from '@/map/views/components/map/asset/assetPopover'

export default {
  mounted: function (map: Map, router: Router): void {
    if (router.currentRoute.value.query.assetPopoverAdd) {
      router.push({ query: { ...router.currentRoute.value.query, assetPopoverAdd: undefined } })
    }

    const subject = computed(function () {
      return categoryStore.state.initialized && router.currentRoute.value.query.assetPopoverAdd ? router.currentRoute.value.query.assetPopoverAdd as string : '-1'
    })

    watch(subject, function (value:string): void {
      const element = document.querySelector('[data-panel-type=asset]')
      if (element) {
        (element as HTMLElement).remove()
      }
      if (value !== '-1') {
        const asset = categoryStore.state.list.find(asset => asset.assetCode === value)
        const marker = (document.querySelector(`[data-asset-code='${value}']`) as HTMLElement).parentElement
        if (marker && asset) {
          assetPopover.append(marker, asset, router)
        }
      }
    },
    {
      flush: 'sync'
    })
  }
}
