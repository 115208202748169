

import { defineComponent, inject } from 'vue'
import DropZone from '@/components/reports/categories/services/reports/form/DropZone.vue'
import FilePreview from '@/components/reports/categories/services/reports/form/FilePreview.vue'
import FormHeader from '@/components/reports/categories/services/reports/form/ServiceReportFormHeader.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import LoaderIcon from '@/components/icons/LoaderIcon.vue'
import UploadIcon from '@/components/icons/UploadIcon.vue'
import { ContextManager } from '@/core/managers/context.manager'
import ReportManager from '@/core/managers/report.manager'
import { UploadServiceReportModel, UploadableFile } from '@/core/model/report.model'

export default defineComponent({
  components: {
    CalendarIcon,
    LoaderIcon,
    UploadIcon,
    FormHeader,
    DropZone,
    FilePreview
  },
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true }
  },
  setup () {
    return {
      contextManager: inject(ContextManager.name) as ContextManager,
      reportManager: ReportManager
    }
  },
  data () {
    return {
      clientDepartmentContext: 'None',
      attributes: [] as Array<any>,
      file: null as UploadableFile|null,
      date: new Date(Date.now()),
      // format: 'pdf',
      name: '',
      attributeId: -1,
      uploading: false
    }
  },
  async created () {
    const contextResponse = await this.contextManager.currentDepartment()
    if (contextResponse.data) {
      this.clientDepartmentContext = contextResponse.data
    }
    this.attributes = await this.reportManager.attributes()
  },
  methods: {
    close () {
      if (this.uploading) return

      this.removeFile()
      this.$emit('close')
    },
    onInputChange (e: any) {
      this.addFiles(e.target.files)
      e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
    },
    isFileValid (file: File) {
      if (file.size > 10 * 1024 * 1024) return false // 5Mbit

      return true
    },
    addFiles (newFiles: File[]) {
      if (!this.isFileValid(newFiles[0])) return

      const newUploadableFile = new UploadableFile(newFiles[0])
      this.file = newUploadableFile
    },
    removeFile () {
      this.file = null
    },
    async upload () {
      if (this.file === null) return
      this.uploading = true
      let shouldBeClose = false
      try {
        const uploadModel = {
          reportId: this.id,
          Title: this.name,
          attributeId: this.attributeId,
          ClientDepartmentCode: this.clientDepartmentContext,
          Date: this.date,
          // Format: this.format,
          File: this.file as UploadableFile
        } as UploadServiceReportModel
        await this.reportManager.upload(uploadModel)
        shouldBeClose = true
      } finally {
        this.uploading = false
      }

      if (shouldBeClose) {
        this.close()
      }
    }
  }
})

