import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "title",
  class: "flex items-center mb-9"
}
const _hoisted_2 = { class: "ml-4" }

import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import { useRouter, useRoute, LocationQueryRaw } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'title',
  props: {
    title: {},
    link: {},
    loading: { type: Boolean },
    error: {},
    description: {},
    params: {},
    small: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const router = useRouter()

function goTo () {
  if (router.options.history.state.back === '/dashboards') {
    router.go(-1)
  } else {
    if (props.params && Object.values(props.params!)[0] !== '0') {
      router.push({
        name: props.link,
        query: props.params
      })
    } else if (props.params && Object.values(props.params!)[0] === '0') {
      router.go(-1)
    } else {
      router.push({ name: props.link })
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: goTo,
      class: "p-2.5 bg-bg-3 rounded-full"
    }, [
      _createVNode(LeftArrowIcon, { class: "h-5 w-5 stroke-gray-100" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.small ? 'h3': 'h1'), {
        class: _normalizeClass([{ skeleton__title: _ctx.loading }, "!leading-7.5"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.error || _ctx.title), 1)
        ]),
        _: 1
      }, 8, ["class"]))
    ])
  ]))
}
}

})