
import { DOMEvent } from '@/core/model/event.model'
import { defineComponent, PropType } from 'vue'
import CloseModal from '@/components/buttons/CloseModal.vue'
import { TimelineModalContent } from '@/core/model/timeline.model'
import ColoursService from '@/core/services/colours.service'
import FormatService from '@/core/services/format.service'

export default defineComponent({
  name: 'FleetAssetTimelineModal',
  components: { CloseModal },
  props: {
    operation: {
      type: Object as PropType<TimelineModalContent>,
      required: true
    }
  },
  setup (props) {
    const getKpiColour = () => {
      return ColoursService.getColour(props.operation.activity.name)
    }

    const formatNumber = (num: number | string) => {
      if (typeof num === 'number') {
        return FormatService.formatNumber(+num)
      }
      if (typeof num === 'string') {
        if (!isNaN(parseInt(num))) {
          const regExp = /[a-zA-Z]/g
          if (regExp.test(num)) {
            return num
          }
          const numberCommaReplacedByDot = num.replace(',', '.')
          return FormatService.formatNumber(+numberCommaReplacedByDot)
        }
        return num
      }
    }

    const formatDate = (str: string) => FormatService.formatDate(str, true)

    return { getKpiColour, formatNumber, formatDate }
  },
  methods: {
    closeModalOnBg ($event: DOMEvent<HTMLElement>) {
      if ($event.target && $event.target.matches('.modal')) {
        this.$emit('close-modal')
      }
    }
  }
})
