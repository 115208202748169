
import ReportsListMenu from '@/components/buttons/ReportsListMenu.vue'
import FilterIcon from '@/components/icons/FilterIcon.vue'
import manageReportsMenu from '@/core/composables/reportsMenu.composable'
import { FleetCategoryModel } from '@/core/model/fleet.model'
import { ReportServiceCategory, Report } from '@/core/model/report.model'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'ReportsListMenuShell',
  components: { ReportsListMenu, FilterIcon },
  props: {
    categories: Array as PropType<ReportServiceCategory[]>,
    // assetOnlyServices: Array as PropType<ReportServiceCategory[]>,
    error: String,
    loading: Boolean,
    assetCategories: Array as PropType<FleetCategoryModel[]>,
    isAsset: { type: Boolean, default: false }
  },
  setup () {
    const manageMenu = manageReportsMenu()
    const filtersDisplayed = ref(manageMenu.filtersDisplayed)
    const openFilters = manageMenu.openFilters
    const closeFilters = manageMenu.closeFilters
    const open = ref(false)
    const allActive = ref(true)
    const allActiveInCategory = ref(true)
    return {
      filtersDisplayed,
      openFilters,
      closeFilters,
      open,
      allActive,
      allActiveInCategory
    }
  },
  created () {
    if (this.isAsset) {
      this.open = true
    }
  },
  methods: {
    handleServiceReport ($e: string, report: ReportServiceCategory) {
      let allActive = -1

      for (const service of this.categories!) {
        if (service.active && service !== report) {
          allActive += 1
        }
      }
      if (allActive > -1) {
        for (const service of this.categories!) {
          service.active = false
        }

        report.active = !report.active
        for (const serv of report.groups) {
          serv.active = true
        }
        this.allActiveInCategory = true
        this.allActive = false
      } else {
        for (const service of this.categories!) {
          service.active = true
          for (const serv of service.groups) {
            serv.active = true
          }
        }
        this.allActiveInCategory = false
        this.allActive = true
      }
    },
    handleDetailReport ($e: string, report: ReportServiceCategory) {
      let allActive = -1
      const serviceSelected = report.groups.find((t) => t.name === $e)
      for (const service of this.categories!) {
        for (const serv of service.groups) {
          if (serv.active && serv !== serviceSelected) {
            allActive += 1
          }
        }
      }

      if (allActive > -1) {
        for (const service of this.categories!) {
          service.active = false
          for (const serv of service.groups) {
            serv.active = false
          }
        }

        serviceSelected!.active = !serviceSelected!.active
        report.active = !report.active
        this.allActiveInCategory = false
        this.allActive = false
      } else {
        for (const service of this.categories!) {
          service.active = true
          for (const serv of service.groups) {
            serv.active = true
          }
        }
        this.allActive = true
        this.allActiveInCategory = true
      }
    },
    handleVesselReport (report: Report) {
      if (!this.isAsset) {
        let allActive = -1
        if (this.categories) {
          // && this.assetOnlyServices) {
          for (const service of this.categories) {
            for (const serv of service.groups) {
              if (serv.active) {
                allActive += 1
              }
            }
          }
          if (allActive > -1) {
            for (const service of this.categories!) {
              service.active = false
              for (const serv of service.groups) {
                serv.active = false
              }
            }
            report.active = !report.active
          } else {
            for (const service of this.categories) {
              service.active = true
              for (const serv of service.groups) {
                serv.active = true
              }
            }
          }
        }
      } else {
        let allActive = -1
        for (const service of this.categories!) {
          for (const serviceGroup of service.groups) {
            for (const group of serviceGroup.groups) {
              if (group.active && group !== report) {
                allActive += 1
              }
            }
          }
        }
        if (allActive > -1) {
          for (const service of this.categories!) {
            for (const serviceGroup of service.groups) {
              for (const group of serviceGroup.groups) {
                group.active = false
              }
            }
          }
          report.active = !report.active
        } else {
          for (const service of this.categories!) {
            for (const serviceGroup of service.groups) {
              for (const group of serviceGroup.groups) {
                group.active = true
              }
            }
          }
        }
      }
    }
  }
})
